import { applyMiddleware, compose, createStore } from 'redux';

import { BROWSE_CUSTOMER } from './common/services/customer';
import DBActions from './common/state/db/DB.actions';
import customerSchema from './common/state/db/customer/Customer.schema';
import { formatCustomer } from './common/services/format';
import fromState from './common/state/selectors';
import middleware from './common/middleware';
import reduxThunk from 'redux-thunk';
import rootReducer from './common/state/reducer';

const isDev = ['dev', 'development', 'ci'].includes(process.env.MODE);

const ignoredActions = ['Amplify'];
if (!isDev) {
    ignoredActions.push('ui/');
    ignoredActions.push('DB/UPDATE');
    ignoredActions.push('@@');
}

export const localStorageKey = 'state';

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(localStorageKey);
        if (serializedState === null) {
            return {};
        }
        return JSON.parse(serializedState) || {};
    } catch (err) {
        return {};
    }
};

let savedState = '';
export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(localStorageKey, serializedState);
        savedState = serializedState;
    } catch {
        // ignore write errors
    }
};

/**
 * Initializes a new Redux store using provided initialState
 *
 * @param {Object} initialState
 * @return {import("redux").Store} A new Redux store
 */
export const configureStore = initialState => {
    const middlewares = [...middleware, reduxThunk];
    const composeEnhancers =
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && // add support for Redux dev tools
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                actionsDenylist: ignoredActions,
            })) ||
        compose;
    let store;
    try {
        store = createStore(
            rootReducer,
            initialState,
            composeEnhancers(applyMiddleware(...middlewares))
        );
    } catch (error) {
        // Bad local storage store
        store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));
    }

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./common/state/reducer', () => {
            const nextRootReducer = require('./common/state/reducer');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
};

const initialState = loadState();
const store = configureStore(initialState);

store.dispatch(
    DBActions.init({
        [customerSchema.key]: [BROWSE_CUSTOMER].map(formatCustomer),
    })
);

export const onStateUpdate = providedState => {
    const state = providedState || store.getState();
    const rememberMe = fromState.Auth.shouldRemember(state);
    const newState = {
        auth: {
            customer: {
                id: fromState.Auth.customer.getId(state),
            },
            rememberMe,
            user: {
                id: fromState.Auth.user.getId(state),
            },
        },
        ui: {
            preventWarning: fromState.UI.getPreventWarning(state),
            resultsView: fromState.UI.getResultsView(state),
        },
    };
    if (JSON.stringify(newState) !== savedState) {
        saveState(newState);
    }
};

store.subscribe(onStateUpdate);

export default store;
