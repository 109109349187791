import { DOMAIN } from './SitemapUrls.constants';
import get from 'lodash/get';
import { planLimitReached } from '../../../common/helpers/Limits';

export const getStore = state => get(state, DOMAIN) || [];

export const getStatus = state => getStore(state).status || {};
export const getStatusById = (state, id) => getStatus(state)[id] || {};

export const getResults = state => getStore(state).results || [];

export const getResultCount = state => getStore(state).resultCount || 0;
export const getError = state => getStore(state).error;
export const getPlanData = state => getStore(state).planData || {};
export const getPlanLimitReached = state => planLimitReached(getPlanData(state));

export const getRequestParams = state => {
    const { offset, limit, query, sort, sortDirection, filters } = getStore(state);
    return {
        offset,
        limit,
        query,
        sort,
        sortDirection,
        filters,
    };
};

export const getRequestFilters = state => getStore(state).filters || {};
export const getRequestFilterByName = (state, field) => getRequestFilters(state)[field];

export const isLoading = state => getStore(state).loading;
export const hasFilters = state =>
    Object.values(getRequestFilters(state)).reduce(
        (hasFilters, filters) => hasFilters || filters.length,
        false
    );
