export default theme => ({
    root: {},
    blackout: {
        zIndex: theme.zIndex.blackout,
    },
    topbar: {
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        right: 'auto',
        zIndex: theme.zIndex.appBar,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    footer: {
        flex: '0 0 auto',
        width: '100%',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: 0,
        position: 'relative',
    },
    topbarShift: {
        marginLeft: theme.width.drawer,
        width: `calc(100vw - ${theme.width.drawer})`,
    },
    drawerRoot: {
        zIndex: theme.zIndex.modal,
    },
    drawerPaper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: theme.width.drawer,
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.common.white,
    },
    sidebar: {
        width: theme.width.drawer,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingTop: theme.height.topBar,
        zIndex: 0,
        position: 'relative',
        backgroundColor: theme.palette.background.default,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    panel: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        position: 'relative',
    },
    contentShift: {
        marginLeft: theme.width.drawer,
    },
});
