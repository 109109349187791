export const MAX_TIMEOUT_SECONDS = 30;
export const MIN_TIMEOUT_SECONDS = 3;
export const TIMEOUT_INCREMENT_STEP = 1;

export const PUBLISH_STATUS = {
    READY: 'ready',
    CATEGORIZE: 'categorize',
    UNPUBLISHED: 'unpublished',
    BUILDING: 'building',
    ERROR: 'error',
    IN_PROGRESS: 'in-progress',
    QUEUED: 'queued',
    SENDING: 'sending',
    UPDATED: 'updated',
    MODE_CHANGE: 'mode-change',
};

export const PUBLISH_STATUS_MESSAGES = {
    ready: 'views.ConsentManager.columns.publishStatus.status.live.label',
    categorize: 'views.ConsentManager.columns.publishStatus.status.live.label', // 'Categorize Your Scripts',
    building: 'views.ConsentManager.columns.publishStatus.status.buildingScript.label',
    error: 'views.ConsentManager.columns.publishStatus.status.publishError.label',
    'in-progress': 'views.ConsentManager.columns.publishStatus.status.publishingInProgress.label',
    queued: 'views.ConsentManager.columns.publishStatus.status.publishingQueued.label',
    sending: 'common.loading',
    updated: 'views.ConsentManager.columns.publishStatus.status.live.label', // 'Unpublished Changes',
    unpublished: 'views.ConsentManager.columns.publishStatus.status.unpublished.label',
    'mode-change': 'views.ConsentManager.columns.publishStatus.status.live.label',
};

export const PUBLISH_STATUS_TOOLTIP = {
    categorize: 'views.ConsentManager.columns.actions.tooltips.categorize.label',
    updated: 'views.ConsentManager.columns.actions.tooltips.updated.label',
};

export const PUBLISH_STATUS_CTA = {
    ready: 'common.emptyString',
    categorize: 'views.ConsentDetails.publishStatus.notCategorized',
    building: 'common.emptyString',
    error: '',
    'in-progress': 'common.emptyString',
    queued: 'common.emptyString',
    sending: '',
    updated: 'views.ConsentDetails.publishStatus.scriptNotActive',
    unpublished: 'common.emptyString',
    'mode-change': 'views.ConsentDetails.publishStatus.scriptNotActive',
};

export const COMPLIANCE_TYPES = {
    DEFAULT: 'no-opt-out',
    OPT_OUT_BLOCK_ANALYTICS: 'opt-out-analytics',
    OPT_IN_BLOCK_ANALYTICS_CATEGORIES: 'opt-in-analytics-categories',
    OPT_IN_BLOCK_ANALYTICS: 'opt-in-analytics',
    OPT_OUT_TIMER: 'opt-out-timer',
    OPT_IN_DIALOG: 'opt-in-dialog',
};

export const COMPLIANCE_ORDER = [
    COMPLIANCE_TYPES.DEFAULT,
    COMPLIANCE_TYPES.OPT_OUT_BLOCK_ANALYTICS,
    COMPLIANCE_TYPES.OPT_IN_BLOCK_ANALYTICS_CATEGORIES,
    COMPLIANCE_TYPES.OPT_IN_BLOCK_ANALYTICS,
    COMPLIANCE_TYPES.OPT_OUT_TIMER,
    COMPLIANCE_TYPES.OPT_IN_DIALOG,
];

export const COMPLIANCE_DATA = {
    [COMPLIANCE_TYPES.DEFAULT]: {
        label: 'Opt-Out / Analytics On / No Categories',
        value: COMPLIANCE_TYPES.DEFAULT,
        helperText: [
            ['Must opt-in to data collection', false],
            ['Required to show category dialog link', true],
            ['Automatically dismisses on a timer', true],
            ['Analytics are blocked by default', false],
            ['Required to show analytics categories', false],
        ],
        configuration: {
            timer: true,
            analyticsAlways: true,
            categories: false,
        },
    },
    [COMPLIANCE_TYPES.OPT_OUT_BLOCK_ANALYTICS]: {
        label: 'Opt-Out / Analytics Off / No Categories',
        value: COMPLIANCE_TYPES.OPT_OUT_BLOCK_ANALYTICS,
        helperText: [
            ['Must opt-in to data collection', false],
            ['Required to show category dialog link', true],
            ['Automatically dismisses on a timer', true],
            ['Analytics are blocked by default', true],
            ['Required to show analytics categories', false],
        ],
        configuration: {
            timer: true,
            analyticsAlways: false,
            categories: false,
        },
    },
    [COMPLIANCE_TYPES.OPT_IN_BLOCK_ANALYTICS_CATEGORIES]: {
        label: 'Opt-In / Categorized Analytics / Show Categories',
        value: COMPLIANCE_TYPES.OPT_IN_BLOCK_ANALYTICS_CATEGORIES,
        helperText: [
            ['Must opt-in to data collection', true],
            ['Required to show category dialog link', true],
            ['Automatically dismisses on a timer', false],
            ['Analytics are blocked by default', true],
            ['Required to show analytics categories', true],
        ],
        configuration: {
            timer: false,
            analyticsAlways: false,
            categories: true,
        },
    },
    [COMPLIANCE_TYPES.OPT_IN_BLOCK_ANALYTICS]: {
        label: 'Opt-In / Analytics Off / No Categories',
        value: COMPLIANCE_TYPES.OPT_IN_BLOCK_ANALYTICS,
        helperText: [
            ['Must opt-in to data collection', true],
            ['Required to show category dialog link', true],
            ['Automatically dismisses on a timer', false],
            ['Analytics are blocked by default', true],
            ['Required to show analytics categories', false],
        ],
        configuration: {
            timer: false,
            analyticsAlways: false,
            categories: false,
        },
    },
    [COMPLIANCE_TYPES.OPT_OUT_TIMER]: {
        label: 'Opt-Out on Timer / Analytics On / No Categories',
        value: COMPLIANCE_TYPES.OPT_OUT_TIMER,
        helperText: [
            ['Must opt-in to data collection', false],
            ['Required to show category dialog link', false],
            ['Automatically dismisses on a timer', true],
            ['Analytics are blocked by default', false],
            ['Required to show analytics categories', false],
        ],
        configuration: {
            timer: true,
            analyticsAlways: true,
            categories: false,
        },
    },
    [COMPLIANCE_TYPES.OPT_IN_DIALOG]: {
        label: 'Opt-In / Analytics Off / No Categories',
        value: COMPLIANCE_TYPES.OPT_IN_DIALOG,
        helperText: [
            ['Must opt-in to data collection', true],
            ['Required to show category dialog link', false],
            ['Automatically dismisses on a timer', false],
            ['Analytics are blocked by default', true],
            ['Required to show analytics categories', false],
        ],
        configuration: {
            timer: false,
            analyticsAlways: false,
            categories: false,
        },
    },
};

export const validConsentConfigModes = ['debug', 'permissive', 'production'];

const defaultTheme = 'theme1';

export const themes = {
    [defaultTheme]: {
        dialogBackgroundColor: '#000',
        buttonBackgroundColor: '#f1d600',
    },
    theme2: {
        dialogBackgroundColor: '#eaf7f7',
        dialogForegroundColor: '#5c7291',
        buttonBackgroundColor: '#56cbdb',
        buttonForegroundColor: '#ffffff',
    },
    theme3: {
        dialogBackgroundColor: '#252e39',
        buttonBackgroundColor: '#14a7d0',
    },
    theme4: {
        dialogBackgroundColor: '#000',
        dialogForegroundColor: '#0f0',
        buttonBackgroundColor: '#0f0',
    },
    theme5: {
        dialogBackgroundColor: '#3937a3',
        buttonBackgroundColor: '#e62576',
    },
    theme6: {
        dialogBackgroundColor: '#64386b',
        dialogForegroundColor: '#ffcdfd',
        buttonBackgroundColor: '#f8a8ff',
        buttonForegroundColor: '#3f0045',
    },
    theme7: {
        dialogBackgroundColor: '#237afc',
        buttonBackgroundColor: '#fff',
        buttonForegroundColor: '#237afc',
    },
    theme8: {
        dialogBackgroundColor: '#aa0000',
        dialogForegroundColor: '#ffdddd',
        buttonBackgroundColor: '#ff0000',
    },
    theme9: {
        dialogBackgroundColor: '#383b75',
        buttonBackgroundColor: '#f1d600',
    },
    theme10: {
        dialogBackgroundColor: '#1d8a8a',
        buttonBackgroundColor: '#62ffaa',
    },
    theme11: {
        dialogBackgroundColor: '#edeff5',
        dialogForegroundColor: '#838391',
        buttonBackgroundColor: '#4b81e8',
    },
    theme12: {
        dialogBackgroundColor: '#343c66',
        dialogForegroundColor: '#cfcfe8',
        buttonBackgroundColor: '#f71559',
    },
    theme13: {
        dialogBackgroundColor: '#216942',
        dialogForegroundColor: '#b2d192',
        buttonBackgroundColor: '#afed71',
    },
    theme14: {
        dialogBackgroundColor: '#3c404d',
        dialogForegroundColor: '#d6d6d6',
        buttonBackgroundColor: '#8bed4f',
    },
    theme15: {
        dialogBackgroundColor: '#eb6c44',
        dialogForegroundColor: '#ffffff',
        buttonBackgroundColor: '#f5d948',
    },
    theme16: {
        dialogBackgroundColor: '#efefef',
        dialogForegroundColor: '#404040',
        buttonBackgroundColor: '#8ec760',
        buttonForegroundColor: '#ffffff',
    },
};

export const dialogTypePositions = {
    box: {
        'top-left': {
            label: 'views.ConsentCustomization.consentDialogPosition.options.topLeft',
            value: 'top-left',
        },
        'top-right': {
            label: 'views.ConsentCustomization.consentDialogPosition.options.topRight',
            value: 'top-right',
        },
        'bottom-left': {
            label: 'views.ConsentCustomization.consentDialogPosition.options.bottomLeft',
            value: 'bottom-left',
        },
        'bottom-right': {
            label: 'views.ConsentCustomization.consentDialogPosition.options.bottomRight',
            value: 'bottom-right',
        },
        center: {
            label: 'views.ConsentCustomization.consentDialogPosition.options.center',
            value: 'center',
        },
    },
    bar: {
        bottom: {
            label: 'views.ConsentCustomization.consentDialogPosition.options.bottom',
            value: 'bottom',
        },
        top: {
            label: 'views.ConsentCustomization.consentDialogPosition.options.top',
            value: 'top',
        },
    },
};

export const defaultConfiguration = {
    additionalLinks: [],
    theme: defaultTheme,
    policyLinkText: 'privacyPolicy',
    policyLinkInDrawer: false,
    storagePolicyHref: '',
    codeSplitting: false,
    crossDomain: false,
    ccpaRelaxed: false,
    dntSupport: true,
    forcedClassifyEnabled: true,
    googleConsent: true,
    remoteConsent: false,
    gpcSupport: true,
    iframeBlocking: '',
    iab: {
        hideOptOut: false,
    },
    iabEnabled: false,
    legacyBrowserSupport: false,
    managePreferencesEnabled: false,
    timeoutSeconds: 10,
    palette: {
        borderless: false,
        dialogType: 'bar',
        displayPosition: 'top',
        widgetPosition: 'right',
        infoDialogPosition: 'right',
        dialogBackgroundColor: '',
        dialogForegroundColor: '',
        infoDialogOverlayColor: '',
        infoDialogBackgroundColor: '',
        infoDialogForegroundColor: '',
        linkColor: '',
        buttonBackgroundColor: '',
        buttonForegroundColor: '',
        toggleOnBackgroundColor: '',
        toggleOffBackgroundColor: '',
        toggleButtonOnColor: '',
        toggleButtonOffColor: '',
        buttonDenyBackgroundColor: '',
        buttonDenyForegroundColor: '',
        ...themes[defaultTheme],
    },
    translations: {},
    allowTimeout: true,
    forceManagePreferences: true,
};

export const defaultColors = Object.entries(defaultConfiguration.palette).reduce(
    (mapped, [key, value]) => {
        if (key.indexOf('Color') >= 0) {
            mapped[key] = value;
        }
        return mapped;
    },
    {}
);

export const LANGUAGES = [
    'af',
    'ar',
    'bg',
    'ca',
    'cs',
    'da',
    'de',
    'de-at',
    'el',
    'en',
    'en-au',
    'en-ca',
    'en-gb',
    'es',
    'es-mx',
    'et',
    'fa',
    'fi',
    'fr',
    'fr-ca',
    'gd',
    'he',
    'hi',
    'hr',
    'hu',
    'hy',
    'id',
    'is',
    'it',
    'ja',
    'kk',
    'ko',
    'lt',
    'lv',
    'mi',
    'ms',
    'nb',
    'nl',
    'nl-be',
    'nn',
    'no',
    'pl',
    'pt',
    'pt-br',
    'ro',
    'ru',
    'sk',
    'sl',
    'sr',
    'sq',
    'sv',
    'th',
    'tl',
    'tr',
    'uk',
    'vi',
    'zh',
    'zh-hk',
    'zh-tw',
    'zu',
];

export const EVENTS = {
    ADD_CONFIG: 'AddConfig',
    // ADD_COOKIE_POLICY: 'AddCookiePolicy',
    ADD_CUSTOM_TEXT: 'AddCustomText',
    ADD_DOMAIN: 'AddDomain',
    // ADD_ORGANIZATION: 'AddOrganization',
    // ADD_SITEMAP_URL: 'AddSitemapURL',
    // ADD_URL_SCAN: 'AddURLScan',
    CATEGORIZE_RULE: 'CategorizeRule',
    // CHANGE_POLICY_LINK: 'ChangePolicyLink',
    // CHANGE_THEME: 'ChangeTheme',
    // COPY_CONFIG: 'CopyConfig',
    CREATE_CONFIG: 'CreateConfig',
    CREATE_RULE: 'CreateRule',
    DELETE_CLASSIFICATION: 'DeleteClassification',
    DELETE_RULE: 'DeleteRule',
    // FILTER_CONSENT_DATA: 'FilterConsentData',
    GET_CODE: 'GetCode',
    // HAS_TRAFFIC: 'HasTraffic',
    // IGNORE_COOKIE: 'IgnoreCookie',
    IGNORE_RULE: 'IgnoreRule',
    PUBLISH_CLEAR: 'PublishClear',
    PUBLISH_CONFIG: 'PublishConfig',
    PUBLISH_KEEP: 'PublishKeep',
    SAVE_CLASSIFICATION: 'SaveClassification',
    SAVE_RULE: 'SaveRule',
    SEARCH_CLASSIFICATION: 'SearchClassification',
    // SEARCH_COOKIE: 'SearchCookie',
    SET_MODE: 'SetMode',
    UPDATE_CODE_SPLITTING: 'UpdateCodeSplitting',
    UPDATE_CPRA_MODE: 'UpdateCPRAMode',
    UPDATE_CROSS_DOMAIN_SUPPORT: 'UpdateCrossDomainSupport',
    UPDATE_DNT_SUPPORT: 'UpdateDoNotTrackSupport',
    UPDATE_FIRST_LAYER_CATEGORIES: 'UpdateFirstLayerCategories',
    UPDATE_FORCED_CLASSIFY_ENABLED: 'UpdateForcedClassifyEnabled',
    UPDATE_GPC_SUPPORT: 'UpdateGPCSupport',
    UPDATE_GOOGLE_CONSENT_MODE: 'UpdateGoogleConsentMode',
    UPDATE_IAB_MODE: 'UpdateIABMode',
    UPDATE_LEGACY_BROWSER_SUPPORT: 'UpdateLegacyBroswerSupport',
    // VIEW_VERSIONS: 'ViewVersions',
    UPDATE_FORCE_MANAGE_PREFERENCES: 'UpdateForceManagePreferences',
    GET_CODE_TRUSTHUB: 'GetCodeTrustHub',
    SELECT_COOKIE_DISCLOSURE: 'SelectCookieDisclosure',
    CREATE_COOKIE_DISCLOSURE: 'CreateCookieDisclosure',
    SELECT_GDPR_REP: 'SelectGdprRep',
};
