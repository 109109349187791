import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    namedParameters,
    noParameters,
    singleParameter,
    withMeta,
} from '../../../common/helpers/ReduxHelpers';

import { DOMAIN } from './ScanUrls.constants';

export const events = {
    updateResult: singleParameter,
};

export const filterEvents = {
    addFilter: namedParameters('field', 'value'),
    removeFilter: namedParameters('field', 'value'),
    setFilter: namedParameters('field', 'value'),
    clearFilter: namedParameters('field'),
    assignFilters: namedParameters('value'),
    resetFilters: noParameters,
};

export const crudEvents = {
    addResult: withMeta(namedParameters('value', { field: 'results' })),
    removeResult: withMeta(namedParameters('value', { field: 'results' })),
    filterResults: withMeta(namedParameters('value', { field: 'results' })),
};

export const asyncEvents = makeAsyncEvents(['fetchResults', 'create', 'delete'], true);
export const statusEvents = makeAsyncEvents(['retrieve', 'update', 'delete'], true);

export const types = makeActionTypes(
    [
        ...Object.keys(events),
        ...Object.keys(filterEvents),
        ...Object.keys(crudEvents),
        ...Object.keys(asyncEvents),
        ...Object.keys(statusEvents),
    ],
    DOMAIN
);

export default buildActionCreators(
    {
        ...events,
        ...filterEvents,
        ...crudEvents,
        ...asyncEvents,
        ...statusEvents,
    },
    types
);
