import {
    types as ActionTypes,
    filterEvents as FilterEvents,
    pagingEvents as PagingEvents,
} from './Tattles.actions';
import {
    collectionReducer,
    crudPagingReducers,
    genericReducer,
} from '../../../common/helpers/ReduxHelpers';

import { EVENTS } from '../../constants';
import analytics from '../../../common/analytics';
import castArray from 'lodash/castArray';
import { combineReducers } from 'redux';
import { filterUnique } from '/b2b/common/helpers/Array';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import reduceReducers from 'reduce-reducers';
import schema from '../db/tattle/Tattle.schema';

const omitNil = obj => omitBy(obj, val => isNil(val));

export const initialState = {
    changes: {},
    error: null,
    errors: [],
    filters: {},
    limit: 25,
    loading: false,
    nextPage: null,
    offset: 0,
    pageNumber: 1,
    query: '',
    resultCount: 0,
    results: [],
    sort: '',
    sortDirection: 'none',
};

const previousState = { ...initialState };

export default reduceReducers(
    initialState,
    combineReducers({
        ...crudPagingReducers(
            initialState,
            Object.keys(PagingEvents).map(key => ActionTypes[key]),
            schema
        ),
        changes: genericReducer(initialState.changes, {
            [ActionTypes.resetChanges]: (state, { id }) =>
                id ? omit(state, id) : initialState.changes,
        }),
        filters: collectionReducer(
            initialState.filters,
            Object.keys(FilterEvents).map(key => ActionTypes[key])
        ),
        errors: genericReducer(initialState.errors, {
            [ActionTypes.resetChanges]: (state, { id }) => state.filter(errorId => errorId !== id),
            [ActionTypes.setError]: (state, { id }) => [...state, id].filter(filterUnique),
            [ActionTypes.removeError]: (state, { id }) => state.filter(errorId => errorId !== id),
        }),
    }),
    genericReducer(initialState, {
        [ActionTypes.fetchResultsBegin]: (state, { params: { offset } }) => {
            const newState = {
                ...state,
                loading: state.results.length === 0 || previousState.offset !== offset,
            };

            previousState.offset = offset;

            return newState;
        },
        [ActionTypes.deleteSuccess]: (state, _, meta) => {
            const { id } = meta;
            const ids = castArray(id);
            if (ids.length) {
                const { changes, errors, results } = state;
                return {
                    ...state,
                    changes: ids.reduce((o, id) => omit(o, id), { ...changes }),
                    results: results.filter(id => !ids.includes(id)),
                    errors: errors.filter(id => !ids.includes(id)),
                };
            }
            return state;
        },
        [ActionTypes.fieldChange]: (state, { id, field, value }) => {
            const { changes, errors } = state;
            if (isNil(value)) {
                // Remove the field change
                if (get(changes, [`${id}`, `${field}`]) === undefined) {
                    // There was no change to undo
                    return state;
                }
                const newState = { ...changes };
                const newTattle = { ...changes[`${id}`] };
                delete newState[`${id}`];
                delete newTattle[`${field}`];
                switch (field) {
                    case 'vendorName': {
                        delete newTattle.vendorId;
                        break;
                    }
                }
                if (newTattle.errors && newTattle.errors[field]) {
                    delete newTattle.errors[field];
                }
                if (Object.keys(newTattle).length > 0) {
                    newState[id] = newTattle;
                }
                return {
                    ...state,
                    changes: newState,
                    errors: Object.keys(newTattle.errors || {}).length
                        ? errors
                        : errors.filter(errorId => errorId !== id),
                };
            }
            const newChanges = { ...changes[id] };
            switch (field) {
                case 'vendorName': {
                    newChanges.vendorName =
                        typeof value === 'string' ? value : value?.vendorName || value?.label || '';
                    newChanges.vendorId = value?.vendorId || value?.value || '';
                    break;
                }
                case 'classification': {
                    analytics.track(EVENTS.CATEGORIZE_RULE);
                    newChanges.classification = value;
                    break;
                }

                default: {
                    newChanges[field] = value;
                    break;
                }
            }
            if (newChanges.errors && newChanges.errors[field]) {
                delete newChanges.errors[field];
            }
            return {
                ...state,
                changes: {
                    ...changes,
                    [id]: newChanges,
                },
                errors: Object.keys(newChanges.errors || {}).length
                    ? errors
                    : errors.filter(errorId => errorId !== id),
            };
        },
        [ActionTypes.validate]: (state, { idOrIds, errors }) => {
            if (Array.isArray(idOrIds)) {
                // lump all errors into a single commit
                let hasNewErrors = false;
                const updates = idOrIds.reduce(
                    (allUpdates, id) => {
                        const err = errors[id];
                        if (err && Object.keys(err).length) {
                            hasNewErrors = true;
                            allUpdates.errors.push(id);
                            allUpdates.changes[id] = {
                                ...state.changes[`${id}`],
                                errors: omitNil({
                                    classification: err.classification,
                                    expiry: err.expiry,
                                    rule: err.rule,
                                    vendorName: err.vendorName,
                                }),
                            };
                        } else if (!err && allUpdates.errors.includes(id)) {
                            hasNewErrors = true;
                            allUpdates.errors = allUpdates.errors.filter(errorId => errorId !== id);
                            delete allUpdates.changes[id].errors;
                        }
                        return allUpdates;
                    },
                    { changes: { ...state.changes }, errors: [...new Set([...state.errors])] }
                );
                return hasNewErrors ? updates : state;
            }
            // Singular ids only beyond this point
            const id = idOrIds;
            if (errors && Object.keys(errors).length) {
                return {
                    ...state,
                    errors: [...new Set([...state.errors, id])],
                    changes: {
                        ...state.changes,
                        [id]: {
                            ...state.changes[`${id}`],
                            errors: omitNil({
                                expiry: errors.expiry && castArray(errors.expiry)[0],
                                rule: errors.rule && castArray(errors.rule)[0],
                                vendorName: errors.vendorName && castArray(errors.vendorName)[0],
                            }),
                        },
                    },
                };
            }

            return {
                ...state,
                errors: state.errors.filter(errorId => errorId !== id),
                changes: {
                    ...state.changes,
                    [id]: omit(state.changes[`${id}`], 'errors'),
                },
            };
        },
    })
);
