import {
    black,
    danger,
    grey,
    paper,
    primary,
    secondary,
    secondaryLight,
    storm,
    tertiary,
    white,
} from '../colors';
import { contrastRgb, parseColor } from '../helpers/Color';

import osanoTheme from '/b2b/common/components/Charts/EChart.theme.json';

const { color: echartsSeriesColors } = osanoTheme.theme;

export default {
    common: {
        black,
        grey,
        white,
        neutral: '#E4E7EB',
        muted: '#9EA0A4',
    },
    primary,
    secondary,
    secondaryLight,
    tertiary,
    success: {
        contrastText: white,
        main: '#37CD84',
        light: '#5FD79D',
        dark: '#2CA46A',
    },
    info: {
        contrastText: white,
        main: '#0288D1',
        light: '#03A9F4',
        dark: '#01579B',
    },
    warning: {
        contrastText: white,
        main: '#F6B047',
        light: '#F8C06C',
        dark: '#C58D39',
    },
    default: {
        contrastText: tertiary.main,
        main: paper,
    },
    danger,
    error: danger,
    text: {
        primary: storm,
        secondary: storm,
        secondaryDisabled: secondaryLight.main,
        disabled: '#696969',
        hint: '#A6B1BB',
        urgent: '#de1200',
    },
    background: {
        default: '#FAFAFA',
        dark: '#172B4D',
        paper: white,
    },
    data1: {
        contrastText: white,
        main: '#332288',
    },
    data2: {
        contrastText: storm,
        main: '#9ED8EE',
    },
    data3: {
        contrastText: storm,
        main: '#D076C7',
    },
    data4: {
        contrastText: storm,
        main: '#A69BF3',
    },
    data5: {
        contrastText: storm,
        main: '#00B1B0',
    },
    data6: {
        contrastText: storm,
        main: '#AA4499',
    },
    data7: {
        contrastText: storm,
        main: '#00B1B0',
    },
    data8: {
        contrastText: storm,
        main: '#D2CFFF',
    },
    // ECharts Series Colors
    ...echartsSeriesColors.reduce(
        (acc, color, i) => (
            (acc[`echartsSeries${i + 1}`] = {
                main: color,
                contrastText: color && contrastRgb.apply(null, parseColor(color)) ? white : black,
            }),
            acc
        ),
        {}
    ),
    border: '#DFE3E8',
    divider: '#DFE3E8',
};
