export { default as Component, Off, On, Variant } from './FeatureFlag.component';
export { default } from './FeatureFlag.container';

export const AUTOMATED_SUMMARY_FEATURE_FLAG = 'EnableAutomatedSummary';
export const AUTOMATED_DELETION_FEATURE_FLAG = 'EnableAutomatedDeletion';
export const REPORTS_FEATURE_FLAG = 'EnableReports';
export const ENABLE_APPLICATIONS_ROLLUP = 'EnableApplicationsRollup';
export const ENABLE_DEMO_AGENT = 'EnableDemoAgent';
export const ENABLE_DEMO_SSO_AGENT = 'EnableDemoSSO';
export const ENABLE_DISCOVERY_SOURCES = 'EnableDiscoverySources';
export const ENABLE_VENDOR_AUTOFOLLOW = 'EnableVendorAutofollow';
export const ENABLE_TOGGLE_USERPILOT_EXPERIENCE = 'EnableToggleUserpilotExperience';
export const ENABLE_BREACH_DATA = 'EnableBreachData';
export const ENABLE_VENDOR_GUIDANCE = 'EnableVendorGuidance';
export const CDP_INTEGRATION = 'CDPIntegration';
export const CLOUD_DB_INTEGRATION = 'CloudDBIntegration';
export const ENABLE_CUSTOM_ASSESSMENTS = 'EnableCustomAssessments';
export const ENABLE_CUSTOM_ASSESSMENT_LOGIC = 'EnableCustomAssessmentsLogic';
export const ENABLE_DSAR_AUDIT_EVENTS = 'EnableDSARAuditEvents';
export const ENABLE_DSAR_IMPORT_CSV = 'EnableDSARImportCSV';
export const ENABLE_DSAR_LOCALIZATION = 'EnableDSARLocalization';
export const ENABLE_DSAR_REPORTING = 'EnableDSARReporting';
export const ENABLE_DSAR_REQUEST_TYPES_V2 = 'EnableDSARRequestTypesV2';
export const ENABLE_DSAR_TEMPLATES = 'EnableDSARTemplates';
export const ENABLE_TEMPLATE_PUBLISHING = 'EnableTemplatePublishing';
export const ENABLE_UC_DASHBOARD = 'EnableUcDashboard';
export const ENABLE_QUESTION_ASSIGNMENT = 'EnableQuestionAssignment';
export const AUTOMATE_DATA_STORE_APPS = 'AutomateDataStoreApps';
export const ENABLE_AUTO_ANSWERS = 'EnableAutoAnswers';
export const ENABLE_DATA_STORE_ASSESSMENTS = 'EnableDataStoreAssessments';
export const ENABLE_ASSESSMENT_ANSWER_OPTION_RISK_LEVELS = 'EnableAssessmentAnswerOptionRiskLevels';
