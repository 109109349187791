import React, { forwardRef } from 'react';

import { NavLink } from 'react-router-dom';

const NavLinkListItem = forwardRef((props, ref) => (
    <li ref={ref}>
        <NavLink {...props} />
    </li>
));

export default NavLinkListItem;
