import Component from './LocalizedTooltip.component';
import { connect } from 'react-redux';
import fromState from '../../state/selectors';

export const mapStateToProps = state => ({
    shouldRetranslate: fromState.Locale.shouldRetranslate(state),
});

/** @type {React.FC<Omit<import('./LocalizedTooltip.component').LocalizedTooltipProps,keyof ReturnType<mapStateToProps>>} */
export default connect(mapStateToProps, {})(Component);
