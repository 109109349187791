/* eslint-disable scanjs-rules/property_localStorage */
import Polyglot from 'node-polyglot';
import { devMode } from '/b2b/authentication/state/Auth.selectors';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import { languageCodeLookup } from './Localization';
import merge from 'lodash/merge';
import supportedLocales from './i18nSupportedLocales.json';
import { validateURL } from './URL';

export const MISSING_TRANSLATIONS_KEY = 'osanoMissingTranslations';

const polyglot = new Polyglot({
    allowMissing: true,
    onMissingKey: key => {
        const keyWithoutLocale = key.split('.').slice(1).join('.');
        if (devMode) {
            // Quick and dirty check for invalid keys
            if (
                !keyWithoutLocale || // Empty key
                /[%{}]/.test(keyWithoutLocale) || // Key has replacement characters
                keyWithoutLocale.indexOf(' ') >= 0 || // Key contains spaces
                keyWithoutLocale.indexOf('.') === -1 || // Key does not contain a dot, meaning it's not a valid path
                (keyWithoutLocale.indexOf('/') >= 0 && validateURL(keyWithoutLocale)) // Key is a URL
            ) {
                return keyWithoutLocale;
            }
            // Keep track of missing translations
            const locale = key.split('.')[0];
            let oldValue;
            try {
                oldValue = JSON.parse(
                    window.localStorage.getItem(MISSING_TRANSLATIONS_KEY) || '{}'
                );
            } catch (e) {
                oldValue = {};
            }
            const missingTranslations = JSON.parse(JSON.stringify(oldValue));
            missingTranslations[keyWithoutLocale] = missingTranslations[keyWithoutLocale] || [];
            try {
                if (!missingTranslations[keyWithoutLocale].includes(locale)) {
                    missingTranslations[keyWithoutLocale].push(locale);
                }
            } catch (e) {
                missingTranslations[keyWithoutLocale] = [locale];
            }
            window.localStorage.setItem(
                MISSING_TRANSLATIONS_KEY,
                JSON.stringify(missingTranslations)
            );
            // Dispatch an event to the current window because we are already listening for storage events
            window.dispatchEvent(
                new StorageEvent('storage', {
                    key: MISSING_TRANSLATIONS_KEY,
                    storageArea: window.localStorage,
                    newValue: JSON.stringify(missingTranslations),
                    oldValue: JSON.stringify(oldValue),
                })
            );
        }
        return keyWithoutLocale;
    },
});
let locales = {};

export const DEFAULT_LOCALE = 'en';
export const RTL_LOCALE_CODES = [
    'ar',
    'arc',
    'ckb',
    'dv',
    'fa',
    'ha',
    'he',
    'khw',
    'ks',
    'ps',
    'ur',
    'uz-af',
    'yi',
];

export const LOCALES = supportedLocales.map(code => ({
    label: languageCodeLookup[code].native,
    value: code,
}));

export const clear = localeCode => {
    if (localeCode) {
        delete locales[localeCode];
    } else {
        locales = {};
        polyglot.clear();
    }
    return polyglot.replace(locales);
};

export const translationExists = (path, localeCode) => {
    if (!path || typeof path !== 'string') {
        return false;
    }

    const localePassed = typeof localeCode === 'string';
    const locale = localePassed ? localeCode : polyglot.locale();
    return polyglot.has(`${locale}.${path}`);
};

export const extend = (phrases, localeCode = 'en', changeToProvidedLocale = true) => {
    locales[localeCode] = merge(locales[localeCode] || {}, phrases);
    polyglot.extend(locales[localeCode], localeCode);
    if (devMode) {
        // Remove the missing translations from the list that are now available
        let oldValue;
        try {
            // eslint-disable-next-line scanjs-rules/property_localStorage
            oldValue = JSON.parse(window.localStorage.getItem(MISSING_TRANSLATIONS_KEY));
        } catch (e) {
            oldValue = {};
        }
        if (oldValue) {
            const missingTranslations = JSON.parse(JSON.stringify(oldValue));
            Object.keys(missingTranslations).forEach(key => {
                if (
                    translationExists(key, localeCode) &&
                    missingTranslations[key].indexOf(localeCode) > -1
                ) {
                    missingTranslations[key].splice(
                        missingTranslations[key].indexOf(localeCode),
                        1
                    );
                    if (missingTranslations[key].length === 0) {
                        delete missingTranslations[key];
                    }
                }
            });
            // eslint-disable-next-line scanjs-rules/property_localStorage
            window.localStorage.setItem(
                MISSING_TRANSLATIONS_KEY,
                JSON.stringify(missingTranslations)
            );
            // Dispatch an event to the current window because we are already listening for storage events
            window.dispatchEvent(
                new StorageEvent('storage', {
                    key: MISSING_TRANSLATIONS_KEY,
                    storageArea: window.localStorage,
                    newValue: JSON.stringify(missingTranslations),
                    oldValue: JSON.stringify(oldValue),
                })
            );
        }
    }
    // Do not change the locale code if we tell the method not to
    changeToProvidedLocale && polyglot.locale(localeCode);
};

export const translate = (path, props = {}) => {
    if (!path || typeof path !== 'string') {
        if (isArray(path)) {
            const { delimiter = ' ' } = props;
            return path
                .filter(v => !isNil(v))
                .map(p => translate(p, props))
                .join(delimiter);
        }
        return path;
    }

    const localePassed = typeof props === 'string';
    const locale = localePassed ? props : props.locale || polyglot.locale();
    const options = localePassed ? {} : { ...props };
    delete options.locale;
    return polyglot.t(`${locale}.${path}`, {
        ...options,
        locale,
    });
};

export const makeScopedTranslate =
    domain =>
    (path, options = {}) =>
        translate(`${domain}.${path}`, options);

export const locale = polyglot.locale.bind(polyglot);

export const determineLocale = () => {
    const localeCode =
        // Some browsers don't support the `localStorage` API, so we need to check for it
        // eslint-disable-next-line scanjs-rules/property_localStorage
        (window.localStorage && window.localStorage.getItem('selectedLocale')) ||
        window.navigator.language;
    const [languageCode] = localeCode.split('-');
    const locale = LOCALES.find(({ value }) => value === languageCode);

    return locale ? locale.value : DEFAULT_LOCALE;
};

export const isRTL = (localeCode = polyglot.locale()) =>
    RTL_LOCALE_CODES.includes(localeCode.slice(0, 2).toLowerCase());
