import askAnalyst from '../../askAnalyst/state/AskAnalyst.reducer';
import auth from '../../authentication/state/Auth.reducer';
import { combineReducers } from 'redux';
import consentConfigDetails from '../../consent/state/ConfigDetails.reducer';
import dashboard from '../../dashboard/state/Dashboard.reducer';
import dataMap from '../../dataMapping/state/DataMap.reducer';
import dataStore from '../../dataStores/state/DataStore.reducer';
import db from './db/DB.reducer';
import dsarFormDetails from '../../dsarForms/state/FormDetails.reducer';
import dsarRequestDetails from '../../dsarRequests/state/requestDetails/RequestDetails.reducer';
import dsarTypes from '../../dsarRequests/state/requestTypes/RequestTypes.reducer';
import litigationDetails from '../../litigation/state/LitigationDetails.reducer';
import locale from './locale/Locale.reducer';
import nav from './nav/Nav.reducer';
import notificationList from '../../notifications/state/NotificationList.reducer';
import organizationDetails from '../../organizations/state/OrganizationDetails.reducer';
import organizationsList from '../../organizations/state/OrganizationsList.reducer';
import quicksight from '../../reports/state/Quicksight.reducer';
import trusthubPageDetails from '../../trusthub/state/TrusthubPageDetails.reducer';
import ui from './ui/UI.reducer';
import userDetails from '../../users/state/UserDetails.reducer';
import userpilot from './userpilot/Userpilot.reducer';

const rootReducer = combineReducers({
    askAnalyst,
    auth,
    consentConfigDetails,
    dashboard,
    dataMap,
    dataStore,
    db,
    dsarRequestDetails,
    dsarFormDetails,
    dsarTypes,
    litigationDetails,
    locale,
    nav,
    notificationList,
    organizationsList,
    organizationDetails,
    quicksight,
    trusthubPageDetails,
    ui,
    userDetails,
    userpilot,
});

export default rootReducer;
