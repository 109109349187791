import { genericReducer, passthroughReducer } from '../../helpers/ReduxHelpers';

import { types as ActionTypes } from './UI.actions';
import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import AuthenticatedPaths from '../../../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import Logger from '../../helpers/Logger';
import { types as NavActionTypes } from '../nav/Nav.actions';
import castArray from 'lodash/castArray';
import { combineReducers } from 'redux';
import { getDocumentTitle } from './UI.selectors';
import { pathMatcher } from './UI.constants';
import reduceReducers from 'reduce-reducers';
import { v4 as uuid } from 'uuid';

export const initialState = {
    notificationsOpen: false,
    blackout: false,
    drawerOpen: false,
    userDetailsOpen: false,
    async: 0,
    snackLookup: {},
    snackOrder: [],
    submenus: {
        assessments: {
            open: null, // null means not open, and control is deferred to the component
        },
        consent: {
            open: null, // null means not open, and control is deferred to the component
        },
        dataMapping: {
            open: null, // null means not open, and control is deferred to the component
        },
        dsar: {
            open: null, // null means not open, and control is deferred to the component
        },
        vendorManagement: {
            open: null, // null means not open, and control is deferred to the component
        },
        reports: {
            open: null, // null means not open, and control is deferred to the component
        },
    },
    roadblocks: null,
    error: '',
    userIdle: null,
    resultsView: {},
    preventWarning: [],
};

const lockAsync = val => val + 1;
const unlockAsync = val => {
    if (val === 0) {
        Logger.log('Semaphore asymetrically unlocked');
        return 0;
    }
    return val - 1;
};

export default reduceReducers(
    initialState,
    combineReducers({
        userIdle: genericReducer(initialState.userIdle, {
            [ActionTypes.setUserIdle]: (state, isUserIdle) => isUserIdle,
        }),
        async: genericReducer(initialState.async, {
            [ActionTypes.asyncBegin]: lockAsync,
            [ActionTypes.asyncFailure]: unlockAsync,
            [ActionTypes.asyncSuccess]: unlockAsync,
        }),
        blackout: passthroughReducer(initialState.blackout),
        roadblocks: genericReducer(initialState.roadblocks, {
            [ActionTypes.asyncBegin]: () => null,
            [ActionTypes.asyncFailure]: (state, { error: { roadblocks } = {} }) =>
                roadblocks || null,
            [ActionTypes.setRoadblock]: (state, { roadblocks }) => roadblocks || null,
            [ActionTypes.clearRoadblock]: () => null,
            [NavActionTypes.routeChanged]: () => null,
        }),
        error: genericReducer(initialState.error, {
            [ActionTypes.asyncBegin]: () => '',
            [ActionTypes.asyncFailure]: (state, { error: { message } = {} }) => message || '',
            [ActionTypes.setRoadblock]: (state, { message }) => message || '',
            [ActionTypes.clearRoadblock]: () => '',
            [NavActionTypes.routeChanged]: () => '',
        }),
        notificationsOpen: genericReducer(initialState.notificationsOpen, {
            [ActionTypes.openNotifications]: () => true,
            [ActionTypes.closeNotifications]: () => false,
            [ActionTypes.openUserDetails]: () => false,
            [NavActionTypes.routeChanged]: () => false,
        }),
        userDetailsOpen: genericReducer(initialState.userDetailsOpen, {
            [ActionTypes.openUserDetails]: () => true,
            [ActionTypes.closeUserDetails]: () => false,
            [ActionTypes.openNotifications]: () => false,
            [NavActionTypes.routeChanged]: () => false,
        }),
        drawerOpen: genericReducer(initialState.drawerOpen, {
            [ActionTypes.openDrawer]: () => true,
            [ActionTypes.closeDrawer]: () => false,
            [ActionTypes.toggleDrawer]: state => !state,
            [NavActionTypes.routeChanged]: () => false,
        }),
        resultsView: genericReducer(initialState.resultsView, {
            [ActionTypes.setResultsView]: (state, { path, view }) => {
                const newState = { ...state };
                if (view === undefined) {
                    delete newState[path];
                    // Invalid submenu
                    return newState;
                }
                newState[path] = view;
                return newState;
            },
        }),
        submenus: genericReducer(initialState.submenus, {
            [ActionTypes.toggleSubmenu]: (state, { force, submenu }) => {
                const { [submenu]: { open } = {} } = state;
                if (open === undefined) {
                    // Invalid submenu
                    return state;
                }
                return {
                    ...state,
                    [submenu]: { open: force ?? !open },
                };
            },
            [NavActionTypes.routeChanged]: (state, { location }) => {
                const { pathname = '' } = location || {};
                const newState = { ...initialState.submenus };
                switch (pathMatcher(pathname)) {
                    case AuthenticatedPaths.ASSESSMENTS:
                    case AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS: {
                        newState.assessments = { open: true };
                        break;
                    }
                    case AuthenticatedPaths.API_KEYS:
                    case AuthenticatedPaths.DATA_STORES: {
                        newState.dataMapping = { open: true };
                        break;
                    }
                    case AuthenticatedPaths.UC_PRIVACY_PROTOCOLS:
                    case AuthenticatedPaths.UC_CONFIGS:
                    case AuthenticatedPaths.UC_DASHBOARD:
                    case AuthenticatedPaths.UC_INTEGRATIONS: {
                        newState.consent = { open: true };
                        break;
                    }
                    case AuthenticatedPaths.DSAR_ACTION_ITEMS:
                    case AuthenticatedPaths.DSAR_FORMS:
                    case AuthenticatedPaths.DSAR_REPORTING:
                    case AuthenticatedPaths.DSAR_REQUESTS: {
                        newState.dsar = { open: true };
                        break;
                    }
                    case AuthenticatedPaths.PRODUCTS:
                    case AuthenticatedPaths.VENDORS:
                    case AuthenticatedPaths.LITIGATION:
                    case AuthenticatedPaths.DOCUMENT_CHANGES:
                    case AuthenticatedPaths.DOCUMENTS: {
                        newState.vendorManagement = { open: true };
                        break;
                    }

                    case AuthenticatedPaths.REPORTS:
                    case AuthenticatedPaths.REPORTS_CONSENT:
                    case AuthenticatedPaths.REPORTS_DATASUBJECT:
                    case AuthenticatedPaths.REPORTS_VENDOR: {
                        newState.reports = { open: true };
                        break;
                    }
                }

                return newState;
            },
        }),
        snackLookup: passthroughReducer(initialState.snackLookup),
        snackOrder: passthroughReducer(initialState.snackOrder),
        preventWarning: genericReducer(initialState.preventWarning, {
            [ActionTypes.addPreventWarning]: (state, pathOrPaths) => {
                const newState = [...state, ...castArray(pathOrPaths)].filter(
                    (item, index, self) => self.indexOf(item) === index
                );
                return newState;
            },
        }),
    }),
    genericReducer(initialState, {
        [AuthActionTypes.logout]: () => initialState,
        [ActionTypes.createSnack]: (state, { message: error, options: payload }) => {
            const { message = error } = error || {};
            const { key = `snack-${uuid()}`, ...options } = payload || {};
            const { snackLookup, snackOrder } = state;
            return {
                ...state,
                snackLookup: {
                    ...snackLookup,
                    [key]: {
                        message,
                        options,
                    },
                },
                snackOrder: [...snackOrder.filter(orderedKey => orderedKey !== key), key],
            };
        },
        [ActionTypes.deleteSnack]: (state, { key }) => {
            const { snackLookup, snackOrder } = state;
            if (!snackLookup[key]) {
                return state;
            }
            const newSnacks = {
                ...snackLookup,
            };
            delete newSnacks[key];
            return {
                ...state,
                snackLookup: newSnacks,
                snackOrder: snackOrder.filter(orderedKey => orderedKey !== key),
            };
        },
        [ActionTypes.deleteAllSnacks]: state => {
            return {
                ...state,
                snackLookup: {},
                snackOrder: [],
            };
        },
    }),
    (state, { action, payload }) => {
        switch (action) {
            case ActionTypes.openUserDetails:
            case ActionTypes.openDrawer:
            case ActionTypes.openNotifications: {
                try {
                    // Close the HelpScout Beacon
                    window.Beacon('close');
                } catch (e) {
                    // Beacon does not exist
                }
                break;
            }
            case NavActionTypes.routeChanged: {
                const { location } = payload || {};
                // Always get the title in english for analytics
                const title = getDocumentTitle(state, 'en', location);
                const event = {
                    type: 'page-viewed',
                    url: document.location.href,
                    title,
                };

                try {
                    window.Beacon('event', event);
                } catch (error) {
                    // Becon does not exist
                }

                try {
                    window.userpilot.track('page-viewed', {
                        url: document.location.href,
                        title,
                    });
                    window.Beacon('suggest');
                    window.userpilot.reload();
                } catch (error) {
                    // Userpilot does not exist
                }
                break;
            }
        }
        return state;
    }
);
