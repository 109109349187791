import { createTheme } from '@mui/material/styles';
import merge from 'lodash/merge';
import muiTheme from './default';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';

export const defaultTheme = {
    palette,
    typography,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                },
            },
        },
        ...Object.entries(overrides).reduce((acc, [key, value]) => {
            const { styleOverrides = value, ...rest } = value;
            acc[key] = { styleOverrides, ...rest };
            return acc;
        }, {}),
    },
    height: {
        topBar: '68px',
    },
    width: {
        drawer: '271px',
    },
    zIndex: {
        appBar: 1150,
        blackout: 1200,
        drawer: 1100,
        modal: 1300,
        loadingWrapper: 9999,
        snackbar: 1400,
        tooltip: 10000,
    },
};

export const noLabelTableTheme = createTheme(
    merge({}, defaultTheme, {
        components: {
            MUIDataTableBodyCell: {
                styleOverrides: {
                    cellStackedSmall: {
                        [muiTheme.breakpoints.down('md')]: {
                            display: 'none',
                        },
                    },
                    responsiveStackedSmall: {
                        [muiTheme.breakpoints.down('md')]: {
                            alignItems: 'center',
                            backgroundColor: 'transparent',
                            width: '100%',
                            '&:nth-child(2)': {
                                backgroundColor: 'transparent',
                                width: '100%',
                            },
                        },
                    },
                },
            },
        },
    })
);

export const toolbarIconsPreventToggle = createTheme(
    merge({}, defaultTheme, {
        components: {
            MUIDataTableToolbar: {
                styleOverrides: {
                    icon: {
                        pointerEvents: 'none',
                    },
                    iconActive: {
                        pointerEvents: 'none',
                    },
                },
            },
        },
    })
);

export const firstLastTableTheme = createTheme(
    merge({}, defaultTheme, {
        components: {
            MUIDataTableBodyCell: {
                styleOverrides: {
                    responsiveStackedSmall: {
                        [muiTheme.breakpoints.down('md')]: {
                            '&:nth-child(2)': {
                                width: '100%',
                                lineBreak: 'anywhere',
                            },
                            '&:last-child': {
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                textAlign: 'right',
                                width: '100%',
                            },
                        },
                    },
                    cellStackedSmall: {
                        [muiTheme.breakpoints.down('md')]: {
                            '&:first-child': {
                                display: 'none',
                            },
                            '&:nth-last-of-type(2)': {
                                display: 'none',
                            },
                        },
                    },
                },
            },
        },
    })
);

export const truncateFirstTextTheme = createTheme(
    merge({}, firstLastTableTheme, {
        components: {
            MUIDataTableBodyCell: {
                styleOverrides: {
                    stackedCommon: {
                        whiteSpace: 'nowrap',
                        width: '0%',
                        '&:nth-child(3)': {
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '1px',
                        },
                    },
                },
            },
        },
    })
);

export const fitContentTheme = (hasPermissionToUpdate, currentView) => {
    const rulesView = currentView === 'classified' || currentView === 'deleted';

    return createTheme(
        merge({}, defaultTheme, firstLastTableTheme, {
            components: {
                MUIDataTableBodyCell: {
                    styleOverrides: {
                        stackedCommon: {
                            whiteSpace: 'nowrap',
                            '&:nth-child(2), &:nth-child(3)': {
                                whiteSpace: 'initial',
                                width: rulesView ? '100%' : '50%',
                                lineBreak: 'anywhere',
                            },
                            '&:nth-child(4), &:nth-child(5)': {
                                whiteSpace: 'initial',
                                width: rulesView ? 'auto' : '50%',
                                lineBreak: rulesView ? 'auto' : 'anywhere',
                            },
                            '&:last-child': {
                                textAlign: 'end',
                            },
                        },
                    },
                },
                MUIDataTableSelectCell: {
                    styleOverrides: {
                        fixedHeader: {
                            [muiTheme.breakpoints.down('md')]: {
                                backgroundColor: palette.common.neutral,
                                display: 'flex',
                                width: hasPermissionToUpdate ? '80px' : '60px',
                                padding: muiTheme.spacing(1, 0, 1, 1),

                                '& ~ *:nth-child(even)': {
                                    justifyContent: 'center',
                                    width: `calc(50% - ${hasPermissionToUpdate ? '60px' : '80px'})`,
                                },

                                '& ~ *:nth-child(odd)': {
                                    width: `calc(50% + ${hasPermissionToUpdate ? '60px' : '80px'})`,
                                },

                                '& ~ *:last-child': {
                                    width: '100%',
                                },
                            },
                        },
                    },
                },
                MuiTablePagination: {
                    styleOverrides: {
                        actions: {
                            marginLeft: !rulesView ? muiTheme.spacing(0) : muiTheme.spacing(3),
                        },
                        input: {
                            marginRight: !rulesView ? muiTheme.spacing(0) : muiTheme.spacing(4),
                        },
                    },
                },
                MuiTypography: {
                    styleOverrides: {
                        root: {
                            '&:nth-of-type(2)': {
                                display: !rulesView ? 'none' : 'block',
                            },
                        },
                    },
                },
            },
        })
    );
};

export default createTheme(defaultTheme);
