import {
    types as ActionTypes,
    asyncEvents as AsyncEvents,
    crudEvents as CRUDEvents,
    filterEvents as FilterEvents,
    statusEvents as StatusEvents,
} from './ScanUrls.actions';
import {
    collectionReducer,
    crudPagingReducers,
    crudStatusByIdReducer,
} from '../../../common/helpers/ReduxHelpers';

import { combineReducers } from 'redux';
import { genericReducer } from '../../../common/helpers/ReduxHelpers';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    offset: 0,
    limit: 25,
    query: '',
    status: {},
    results: [],
    resultCount: 0,
    error: null,
    loading: false,
    filters: {},
    sort: '',
    sortDirection: 'none',
    planData: {},
};

export default reduceReducers(
    initialState,
    combineReducers({
        filters: collectionReducer(
            initialState.filters,
            Object.keys(FilterEvents).map(key => ActionTypes[key])
        ),
        status: crudStatusByIdReducer(
            initialState.status,
            Object.keys(StatusEvents).map(key => ActionTypes[key])
        ),
        ...crudPagingReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
    }),
    collectionReducer(
        initialState,
        Object.keys(CRUDEvents).map(key => ActionTypes[key])
    ),
    genericReducer(initialState, {
        [ActionTypes.updateResult]: (state, result) => {
            const { url: id } = result;
            const { results } = state;
            const index = results.findIndex(({ url }) => url === id);
            if (index === -1) {
                return state;
            }
            return {
                ...state,
                results: [
                    ...results.slice(0, index),
                    { ...results[index], ...result },
                    ...results.slice(index + 1),
                ],
            };
        },
    })
);
