import { Collapse, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import {
    DSAR_REQUEST_PATHS,
    PERMISSIONS,
    UNIFIED_CONSENT_PATHS,
} from '/b2b/authentication/constants';
import FeatureFlag, {
    ENABLE_CUSTOM_ASSESSMENTS,
    ENABLE_DISCOVERY_SOURCES,
    ENABLE_DSAR_REPORTING,
    ENABLE_UC_DASHBOARD,
    Off,
    On,
    REPORTS_FEATURE_FLAG,
} from '/b2b/common/components/FeatureFlag';
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { forwardRef, useCallback } from 'react';

import AskExpertIcon from '/b2b/common/icons/AskExpert';
import AssessmentIcon from '/b2b/common/icons/Assessments';
import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
// import ActivityAuditIcon from '@mui/icons-material/Fingerprint';
// import BookmarksIcon from '@mui/icons-material/BookmarksOutlined';
import ConsentIcon from '/b2b/common/icons/ConsentManagement';
import DSARIcon from '/b2b/common/icons/DSAR';
import DataMappingIcon from '/b2b/common/icons/DataMapping';
// import DatasourceIcon from '/b2b/icons/Datasource';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GDPRIcon from '/b2b/common/icons/GDPR';
import LegislationIcon from '/b2b/common/icons/Legislation';
import Locale from '/b2b/common/components/Locale';
import NavLinkListItem from '/b2b/common/components/NavLinkListItem';
import { Logo as OsanoLogo } from '/b2b/common/icons/Osano';
import Permission from '/b2b/common/components/Permission';
import PropTypes from 'prop-types';
import ReportsIcon from '/b2b/common/icons/Reports';
import TemplatesIcon from '/b2b/common/icons/Templates';
import TrusthubIcon from '/b2b/common/icons/Trusthub';
import UnifiedConsentIcon from '@mui/icons-material/Rule';
import VendorIcon from '/b2b/common/icons/Vendors';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Sidebar.styles';

const useStyles = makeStyles(styles);

const Sidebar = forwardRef((props, sidebarRef) => {
    const {
        canManageSubjectRights,
        canManageVendors,
        canReadDataMapping,
        className,
        hasPermissionToReadAssessments,
        hasPermissionToReadUnifiedConsent,
        hasPermissionToViewAssessments,
        hasPermissionToViewUnifiedConsent,
        submenus,
        toggleSubmenu,
    } = props;
    const classes = useStyles(props);
    const routerLocation = useLocation();
    const history = useHistory();
    const rootClassName = classNames(classes.root, className);
    const assessmentsActive = [
        AuthenticatedPaths.ASSESSMENTS,
        AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS,
        AuthenticatedPaths.ASSESSMENT_TEMPLATES,
    ].some(path => routerLocation.pathname.indexOf(path) === 0);

    const consentActive = [UNIFIED_CONSENT_PATHS].some(
        path => routerLocation.pathname.indexOf(path) === 0
    );
    const dsarActive = DSAR_REQUEST_PATHS.some(path => routerLocation.pathname.indexOf(path) === 0);
    const dataMappingActive = [
        AuthenticatedPaths.DATA_STORES,
        AuthenticatedPaths.DATA_MAPPING_SOURCES,
        AuthenticatedPaths.DATA_MAPPING_APPLICATIONS,
        AuthenticatedPaths.DATA_MAP,
    ].some(path => routerLocation.pathname.indexOf(path) === 0);
    const reportsActive = [
        AuthenticatedPaths.REPORTS_CONSENT,
        AuthenticatedPaths.REPORTS_DATASUBJECT,
        AuthenticatedPaths.REPORTS_VENDOR,
    ].some(path => routerLocation.pathname.indexOf(path) === 0);
    const vendorManagementActive = [
        AuthenticatedPaths.VENDORS,
        AuthenticatedPaths.PRODUCTS,
        AuthenticatedPaths.LITIGATION,
        AuthenticatedPaths.DOCUMENT_CHANGES,
        AuthenticatedPaths.DOCUMENTS,
    ].some(path => routerLocation.pathname.indexOf(path) === 0);

    const assessmentsOpen =
        hasPermissionToReadAssessments && (submenus.assessments.open ?? assessmentsActive);
    const consentOpen =
        hasPermissionToReadUnifiedConsent && (submenus.consent.open ?? consentActive);
    const dsarOpen = submenus.dsar.open ?? dsarActive;
    const dataMappingOpen = submenus.dataMapping.open ?? dataMappingActive;
    const reportsOpen = submenus.reports.open ?? reportsActive;
    const vendorManagementOpen = submenus.vendorManagement.open ?? vendorManagementActive;

    const handleToggleConsent = useCallback(
        force => {
            if (hasPermissionToViewUnifiedConsent && hasPermissionToReadUnifiedConsent) {
                toggleSubmenu('consent', force);
            } else {
                history.push(AuthenticatedPaths.CONSENT_MANAGER);
            }
        },
        [toggleSubmenu, hasPermissionToViewUnifiedConsent, hasPermissionToReadUnifiedConsent]
    );
    const handleToggleDSAR = useCallback(
        force => {
            toggleSubmenu('dsar', force);
        },
        [toggleSubmenu]
    );
    const handleToggleDataMapping = useCallback(
        force => {
            toggleSubmenu('dataMapping', force);
        },
        [toggleSubmenu]
    );
    const handleToggleReports = useCallback(
        force => {
            toggleSubmenu('reports', force);
        },
        [toggleSubmenu]
    );
    const handleToggleVendorManagement = useCallback(
        force => {
            toggleSubmenu('vendorManagement', force);
        },
        [toggleSubmenu]
    );
    const handleToggleAssessments = useCallback(
        force => {
            if (hasPermissionToViewAssessments && hasPermissionToReadAssessments) {
                toggleSubmenu('assessments', force);
            } else {
                history.push(
                    hasPermissionToViewAssessments
                        ? AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS
                        : // Force navigate to assessments to trigger the Userpilot experience
                          AuthenticatedPaths.ASSESSMENTS
                );
            }
        },
        [hasPermissionToReadAssessments, hasPermissionToViewAssessments, toggleSubmenu]
    );

    return (
        <nav ref={sidebarRef} className={rootClassName}>
            <div className={classes.logoWrapper}>
                <Link className={classes.logoLink} to="/">
                    <OsanoLogo className={classes.logo} alt="Osano logo" />
                </Link>
            </div>
            <div className={classes.logoDivider} />
            <Permission
                forRole
                toPerform="read"
                onAny={[
                    PERMISSIONS.CONSENTS,
                    PERMISSIONS.DSAR_DATASOURCE,
                    PERMISSIONS.DSAR_REQUEST,
                    PERMISSIONS.DATA_MAPPING,
                    PERMISSIONS.UNIFIED_CONSENT,
                ]}
            >
                <List key="management" component="div" className={classes.list} disablePadding>
                    <Permission forRole toPerform="read" on={PERMISSIONS.CONSENTS}>
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.CONSENT_MANAGER}
                            isActive={(match, location) =>
                                match
                                    ? true
                                    : location.pathname.indexOf(
                                          `${AuthenticatedPaths.CONSENT_MANAGER}/`
                                      ) === 0
                            }
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <ConsentIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={<Locale path={'views.ConsentManager.title'} />}
                            />
                        </ListItem>
                    </Permission>
                    {hasPermissionToReadUnifiedConsent && hasPermissionToViewUnifiedConsent ? (
                        <>
                            <ListItem
                                className={classNames(
                                    classes.listMenu,
                                    classes.listItem,
                                    consentOpen ? classes.listMenuExpanded : '',
                                    consentActive ? classes.activeListItem : ''
                                )}
                                onClick={() => handleToggleConsent(!consentOpen)}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <UnifiedConsentIcon />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary={<Locale path={'title.unifiedConsent'} />}
                                />
                                {consentOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse
                                in={consentOpen}
                                timeout="auto"
                                classes={{
                                    root: classNames(classes.submenu),
                                }}
                            >
                                <List
                                    component="div"
                                    className={classes.submenuList}
                                    disablePadding
                                >
                                    <FeatureFlag flag={ENABLE_UC_DASHBOARD}>
                                        <On>
                                            <ListItem
                                                key={AuthenticatedPaths.UC_DASHBOARD}
                                                activeClassName={classes.activeListItem}
                                                classes={{
                                                    root: classNames(
                                                        classes.listItem,
                                                        classes.submenuItem
                                                    ),
                                                }}
                                                component={NavLinkListItem}
                                                to={AuthenticatedPaths.UC_DASHBOARD}
                                                isActive={(match, location) =>
                                                    match
                                                        ? true
                                                        : location.pathname.indexOf(
                                                              `${AuthenticatedPaths.UC_DASHBOARD}/`
                                                          ) === 0
                                                }
                                            >
                                                <ListItemText
                                                    classes={{
                                                        primary: classNames(
                                                            classes.submenuItemText,
                                                            classes.listItemText
                                                        ),
                                                    }}
                                                    primary={<Locale path={'title.dashboard'} />}
                                                />
                                            </ListItem>
                                        </On>
                                    </FeatureFlag>
                                    <ListItem
                                        key={AuthenticatedPaths.UC_PRIVACY_PROTOCOLS}
                                        activeClassName={classes.activeListItem}
                                        classes={{
                                            root: classNames(classes.listItem, classes.submenuItem),
                                        }}
                                        component={NavLinkListItem}
                                        to={AuthenticatedPaths.UC_PRIVACY_PROTOCOLS}
                                        isActive={(match, location) =>
                                            match
                                                ? true
                                                : location.pathname.indexOf(
                                                      `${AuthenticatedPaths.UC_PRIVACY_PROTOCOLS}/`
                                                  ) === 0
                                        }
                                    >
                                        <ListItemText
                                            classes={{
                                                primary: classNames(
                                                    classes.submenuItemText,
                                                    classes.listItemText
                                                ),
                                            }}
                                            primary={<Locale path={'uc.title.protocols'} />}
                                        />
                                    </ListItem>
                                    <ListItem
                                        key={AuthenticatedPaths.UC_CONFIGS}
                                        activeClassName={classes.activeListItem}
                                        classes={{
                                            root: classNames(classes.listItem, classes.submenuItem),
                                        }}
                                        component={NavLinkListItem}
                                        to={AuthenticatedPaths.UC_CONFIGS}
                                        isActive={(match, location) =>
                                            match
                                                ? true
                                                : location.pathname.indexOf(
                                                      `${AuthenticatedPaths.UC_CONFIGS}/`
                                                  ) === 0
                                        }
                                    >
                                        <ListItemText
                                            classes={{
                                                primary: classNames(
                                                    classes.submenuItemText,
                                                    classes.listItemText
                                                ),
                                            }}
                                            primary={<Locale path={'uc.title.configurations'} />}
                                        />
                                    </ListItem>
                                    <ListItem
                                        key={AuthenticatedPaths.UC_INTEGRATIONS}
                                        activeClassName={classes.activeListItem}
                                        classes={{
                                            root: classNames(classes.listItem, classes.submenuItem),
                                        }}
                                        component={NavLinkListItem}
                                        to={AuthenticatedPaths.UC_INTEGRATIONS}
                                        isActive={(match, location) =>
                                            match
                                                ? true
                                                : location.pathname.indexOf(
                                                      `${AuthenticatedPaths.UC_INTEGRATIONS}/`
                                                  ) === 0
                                        }
                                    >
                                        <ListItemText
                                            classes={{
                                                primary: classNames(
                                                    classes.submenuItemText,
                                                    classes.listItemText
                                                ),
                                            }}
                                            primary={<Locale path={'uc.title.integrations'} />}
                                        />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </>
                    ) : null}
                    <Permission
                        forRole
                        toPerform="read"
                        onAny={[PERMISSIONS.DSAR_DATASOURCE, PERMISSIONS.DSAR_REQUEST]}
                    >
                        <ListItem
                            key="DSAR"
                            className={classNames(
                                classes.listMenu,
                                classes.listItem,
                                dsarOpen ? classes.listMenuExpanded : '',
                                dsarActive ? classes.activeListItem : ''
                            )}
                            onClick={() => handleToggleDSAR(!dsarOpen)}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <DSARIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={
                                    <Locale
                                        path={
                                            'layouts.Dashboard.components.Sidebar.dataSubjectRequests.label'
                                        }
                                    />
                                }
                            />
                            {dsarOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse
                            in={dsarOpen}
                            timeout="auto"
                            classes={{
                                root: classNames(classes.submenu),
                            }}
                        >
                            <List component="div" className={classes.submenuList} disablePadding>
                                <FeatureFlag flag={ENABLE_DSAR_REPORTING}>
                                    <On>
                                        <ListItem
                                            key={AuthenticatedPaths.DSAR_REPORTING}
                                            activeClassName={classes.activeListItem}
                                            classes={{
                                                root: classNames(
                                                    classes.listItem,
                                                    classes.submenuItem
                                                ),
                                            }}
                                            component={NavLinkListItem}
                                            to={AuthenticatedPaths.DSAR_REPORTING}
                                            isActive={(match, location) =>
                                                match
                                                    ? true
                                                    : location.pathname.indexOf(
                                                          `${AuthenticatedPaths.DSAR_REPORTING}/`
                                                      ) === 0
                                            }
                                        >
                                            <ListItemText
                                                classes={{
                                                    primary: classNames(
                                                        classes.submenuItemText,
                                                        classes.listItemText
                                                    ),
                                                }}
                                                primary={<Locale path="common.reporting" />}
                                            />
                                        </ListItem>
                                    </On>
                                </FeatureFlag>
                                <Permission
                                    forRole
                                    toPerform="read"
                                    on={PERMISSIONS.DSAR_DATASOURCE}
                                >
                                    <ListItem
                                        key={AuthenticatedPaths.DSAR_ACTION_ITEMS}
                                        activeClassName={classes.activeListItem}
                                        classes={{
                                            root: classNames(classes.listItem, classes.submenuItem),
                                        }}
                                        component={NavLinkListItem}
                                        to={`${AuthenticatedPaths.DSAR_ACTION_ITEMS}`}
                                        isActive={(match, location) =>
                                            match
                                                ? true
                                                : location.pathname.indexOf(
                                                      `${AuthenticatedPaths.DSAR_ACTION_ITEMS}/`
                                                  ) === 0
                                        }
                                    >
                                        <ListItemText
                                            classes={{
                                                primary: classNames(
                                                    classes.submenuItemText,
                                                    classes.listItemText
                                                ),
                                            }}
                                            primary={
                                                <Locale
                                                    path={
                                                        'layouts.Dashboard.components.Sidebar.dataSubjectRequests.submenu.actionItemList.label'
                                                    }
                                                />
                                            }
                                        />
                                    </ListItem>
                                </Permission>
                                <Permission forRole toPerform="read" on={PERMISSIONS.DSAR_REQUEST}>
                                    <ListItem
                                        key={`${AuthenticatedPaths.DSAR_REQUESTS}`}
                                        activeClassName={classes.activeListItem}
                                        classes={{
                                            root: classNames(classes.listItem, classes.submenuItem),
                                        }}
                                        component={NavLinkListItem}
                                        to={`${AuthenticatedPaths.DSAR_REQUESTS}`}
                                        isActive={(match, location) =>
                                            match
                                                ? true
                                                : location.pathname.indexOf(
                                                      `${AuthenticatedPaths.DSAR_REQUESTS}/`
                                                  ) === 0
                                        }
                                    >
                                        <ListItemText
                                            classes={{
                                                primary: classNames(
                                                    classes.submenuItemText,
                                                    classes.listItemText
                                                ),
                                            }}
                                            primary={
                                                <Locale
                                                    path={
                                                        'layouts.Dashboard.components.Sidebar.dataSubjectRequests.submenu.requestSubmissions.label'
                                                    }
                                                />
                                            }
                                        />
                                    </ListItem>
                                    <ListItem
                                        key={AuthenticatedPaths.DSAR_FORMS}
                                        activeClassName={classes.activeListItem}
                                        classes={{
                                            root: classNames(classes.listItem, classes.submenuItem),
                                        }}
                                        component={NavLinkListItem}
                                        to={AuthenticatedPaths.DSAR_FORMS}
                                        isActive={(match, location) =>
                                            match
                                                ? true
                                                : location.pathname.indexOf(
                                                      `${AuthenticatedPaths.DSAR_FORMS}/`
                                                  ) === 0
                                        }
                                    >
                                        <ListItemText
                                            classes={{
                                                primary: classNames(
                                                    classes.submenuItemText,
                                                    classes.listItemText
                                                ),
                                            }}
                                            primary={
                                                <Locale
                                                    path={
                                                        'layouts.Dashboard.components.Sidebar.dataSubjectRequests.submenu.requestSubmissionForms.label'
                                                    }
                                                />
                                            }
                                        />
                                    </ListItem>
                                </Permission>
                            </List>
                        </Collapse>
                    </Permission>
                    <Permission
                        forRole
                        toPerform="read"
                        onAny={[
                            PERMISSIONS.DATA_MAPPING,
                            PERMISSIONS.DATA_DISCOVERY,
                            PERMISSIONS.DSAR_REQUEST,
                        ]}
                    >
                        <ListItem
                            className={classNames(
                                classes.listMenu,
                                classes.listItem,
                                dataMappingOpen ? classes.listMenuExpanded : '',
                                dataMappingActive ? classes.activeListItem : ''
                            )}
                            onClick={() => handleToggleDataMapping(!dataMappingOpen)}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <DataMappingIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={
                                    <FeatureFlag flag={ENABLE_DISCOVERY_SOURCES}>
                                        <On>
                                            <Locale path="layouts.Dashboard.components.Sidebar.dataMapping.label" />
                                        </On>
                                        <Off>
                                            <Locale path="layouts.Dashboard.components.Sidebar.dataDiscovery.label" />
                                        </Off>
                                    </FeatureFlag>
                                }
                            />
                            {dataMappingOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse
                            in={dataMappingOpen}
                            timeout="auto"
                            unmountOnExit
                            classes={{
                                root: classNames(classes.submenu),
                            }}
                        >
                            <List component="div" className={classes.submenuList} disablePadding>
                                {canReadDataMapping && (
                                    <FeatureFlag flag={ENABLE_DISCOVERY_SOURCES}>
                                        <On>
                                            <ListItem
                                                activeClassName={classes.activeListItem}
                                                classes={{
                                                    root: classNames(
                                                        classes.listItem,
                                                        classes.submenuItem
                                                    ),
                                                }}
                                                component={NavLinkListItem}
                                                to={`${AuthenticatedPaths.DATA_MAPPING_SOURCES}`}
                                                isActive={(match, location) =>
                                                    match
                                                        ? true
                                                        : location.pathname.indexOf(
                                                              `${AuthenticatedPaths.DATA_MAPPING_SOURCES}/`
                                                          ) === 0
                                                }
                                            >
                                                <ListItemText
                                                    classes={{
                                                        primary: classNames(
                                                            classes.submenuItemText,
                                                            classes.listItemText
                                                        ),
                                                    }}
                                                    primary={
                                                        <Locale path="layouts.Dashboard.components.Sidebar.dataMapping.submenu.dataSources.label" />
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                activeClassName={classes.activeListItem}
                                                classes={{
                                                    root: classNames(
                                                        classes.listItem,
                                                        classes.submenuItem
                                                    ),
                                                }}
                                                component={NavLinkListItem}
                                                to={`${AuthenticatedPaths.DATA_MAPPING_APPLICATIONS}`}
                                                isActive={(match, location) =>
                                                    match
                                                        ? true
                                                        : location.pathname.indexOf(
                                                              `${AuthenticatedPaths.DATA_MAPPING_APPLICATIONS}/`
                                                          ) === 0
                                                }
                                            >
                                                <ListItemText
                                                    classes={{
                                                        primary: classNames(
                                                            classes.submenuItemText,
                                                            classes.listItemText
                                                        ),
                                                    }}
                                                    primary={
                                                        <Locale path="layouts.Dashboard.components.Sidebar.dataMapping.submenu.applications.label" />
                                                    }
                                                />
                                            </ListItem>
                                        </On>
                                    </FeatureFlag>
                                )}
                                {(canReadDataMapping || canManageSubjectRights) && (
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        classes={{
                                            root: classNames(classes.listItem, classes.submenuItem),
                                        }}
                                        component={NavLinkListItem}
                                        to={`${AuthenticatedPaths.DATA_STORES}`}
                                        isActive={(match, location) =>
                                            match
                                                ? true
                                                : location.pathname.indexOf(
                                                      `${AuthenticatedPaths.DATA_STORES}/`
                                                  ) === 0
                                        }
                                    >
                                        <ListItemText
                                            classes={{
                                                primary: classNames(
                                                    classes.submenuItemText,
                                                    classes.listItemText
                                                ),
                                            }}
                                            primary={
                                                <Locale
                                                    path={
                                                        'layouts.Dashboard.components.Sidebar.dataMapping.submenu.dataProviders.label'
                                                    }
                                                />
                                            }
                                        />
                                    </ListItem>
                                )}
                                {canReadDataMapping && (
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        classes={{
                                            root: classNames(classes.listItem, classes.submenuItem),
                                        }}
                                        component={NavLinkListItem}
                                        to={`${AuthenticatedPaths.DATA_MAP}`}
                                        isActive={(match, location) =>
                                            match
                                                ? true
                                                : location.pathname.indexOf(
                                                      `${AuthenticatedPaths.DATA_MAP}/`
                                                  ) === 0
                                        }
                                    >
                                        <ListItemText
                                            classes={{
                                                primary: classNames(
                                                    classes.submenuItemText,
                                                    classes.listItemText
                                                ),
                                            }}
                                            primary={
                                                <Locale path="layouts.Dashboard.components.Sidebar.dataMapping.submenu.dataMap.label" />
                                            }
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </Collapse>
                    </Permission>
                </List>
            </Permission>
            <List component="div" className={classes.list} disablePadding>
                <ListItem
                    className={classNames(
                        classes.listMenu,
                        classes.listItem,
                        vendorManagementOpen ? classes.listMenuExpanded : '',
                        vendorManagementActive ? classes.activeListItem : ''
                    )}
                    onClick={() => handleToggleVendorManagement(!vendorManagementOpen)}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <VendorIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary={
                            <Locale
                                path={'layouts.Dashboard.components.Sidebar.vendorManagement.label'}
                            />
                        }
                    />
                    {vendorManagementOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                    in={vendorManagementOpen}
                    timeout="auto"
                    unmountOnExit
                    classes={{
                        root: classNames(classes.submenu),
                    }}
                >
                    <List component="div" className={classes.submenuList} disablePadding>
                        <ListItem
                            activeClassName={classes.activeListItem}
                            classes={{
                                root: classNames(classes.listItem, classes.submenuItem),
                            }}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.VENDORS}
                            isActive={(match, location) =>
                                match
                                    ? true
                                    : location.pathname.indexOf(
                                          `${AuthenticatedPaths.VENDORS}/`
                                      ) === 0
                            }
                        >
                            <ListItemText
                                classes={{
                                    primary: classNames(
                                        classes.submenuItemText,
                                        classes.listItemText
                                    ),
                                }}
                                primary={
                                    <Locale
                                        path={'layouts.Dashboard.components.Sidebar.vendors.label'}
                                    />
                                }
                            />
                        </ListItem>
                        <ListItem
                            activeClassName={classes.activeListItem}
                            classes={{
                                root: classNames(classes.listItem, classes.submenuItem),
                            }}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.LITIGATION}
                            isActive={(match, location) =>
                                match
                                    ? true
                                    : location.pathname.indexOf(
                                          `${AuthenticatedPaths.LITIGATION}/`
                                      ) === 0
                            }
                        >
                            <ListItemText
                                classes={{
                                    primary: classNames(
                                        classes.submenuItemText,
                                        classes.listItemText
                                    ),
                                }}
                                primary={
                                    <Locale
                                        path={
                                            'layouts.Dashboard.components.Sidebar.vendorLawsuits.label'
                                        }
                                    />
                                }
                            />
                        </ListItem>
                        <ListItem
                            activeClassName={classes.activeListItem}
                            classes={{
                                root: classNames(classes.listItem, classes.submenuItem),
                            }}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.DOCUMENT_CHANGES}
                            isActive={(match, location) =>
                                match
                                    ? true
                                    : location.pathname.indexOf(
                                          `${AuthenticatedPaths.DOCUMENT_CHANGES}/`
                                      ) === 0 ||
                                      location.pathname.indexOf(
                                          `${AuthenticatedPaths.DOCUMENTS}/`
                                      ) === 0
                            }
                        >
                            <ListItemText
                                classes={{
                                    primary: classNames(
                                        classes.submenuItemText,
                                        classes.listItemText
                                    ),
                                }}
                                primary={
                                    <Locale
                                        path={
                                            'layouts.Dashboard.components.Sidebar.policyChanges.label'
                                        }
                                    />
                                }
                            />
                        </ListItem>
                    </List>
                </Collapse>
            </List>
            <List component="div" className={classes.list} disablePadding>
                <ListItem
                    className={classNames(
                        classes.listMenu,
                        classes.listItem,
                        assessmentsOpen ? classes.listMenuExpanded : '',
                        assessmentsActive ? classes.activeListItem : ''
                    )}
                    onClick={() => handleToggleAssessments(!assessmentsOpen)}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary={
                            <Locale
                                path={'layouts.Dashboard.components.Sidebar.assessments.label'}
                            />
                        }
                    />
                    {!hasPermissionToViewAssessments ||
                    !hasPermissionToReadAssessments ? null : assessmentsOpen ? (
                        <ExpandLess />
                    ) : (
                        <ExpandMore />
                    )}
                </ListItem>
                <Permission toPerform="view" on={AuthenticatedPaths.ASSESSMENTS}>
                    <Collapse
                        in={assessmentsOpen}
                        timeout="auto"
                        unmountOnExit
                        classes={{
                            root: classNames(classes.submenu),
                        }}
                    >
                        <List component="div" className={classes.submenuList} disablePadding>
                            <Permission
                                forRole
                                toPerform="read"
                                onAny={[PERMISSIONS.ADMIN, PERMISSIONS.ASSESSMENT]}
                            >
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    classes={{
                                        root: classNames(classes.listItem, classes.submenuItem),
                                    }}
                                    component={NavLinkListItem}
                                    to={AuthenticatedPaths.ASSESSMENTS}
                                    isActive={(match, location) =>
                                        location.pathname.indexOf(
                                            `${AuthenticatedPaths.ASSESSMENTS}`
                                        ) === 0 &&
                                        location.pathname.indexOf(
                                            `${AuthenticatedPaths.ASSESSMENT_TEMPLATES}`
                                        ) !== 0 &&
                                        location.pathname.indexOf(
                                            `${AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS}`
                                        ) !== 0
                                    }
                                >
                                    <ListItemText
                                        classes={{
                                            primary: classNames(
                                                classes.submenuItemText,
                                                classes.listItemText
                                            ),
                                        }}
                                        primary={
                                            <Locale
                                                path={
                                                    'layouts.Dashboard.components.Sidebar.assessments.label'
                                                }
                                            />
                                        }
                                    />
                                </ListItem>
                                <FeatureFlag flag={ENABLE_CUSTOM_ASSESSMENTS}>
                                    <On>
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            classes={{
                                                root: classNames(
                                                    classes.listItem,
                                                    classes.submenuItem
                                                ),
                                            }}
                                            component={NavLinkListItem}
                                            to={AuthenticatedPaths.ASSESSMENT_TEMPLATES}
                                            isActive={(match, location) =>
                                                location.pathname.indexOf(
                                                    `${AuthenticatedPaths.ASSESSMENT_TEMPLATES}`
                                                ) === 0
                                            }
                                        >
                                            <ListItemText
                                                classes={{
                                                    primary: classNames(
                                                        classes.submenuItemText,
                                                        classes.listItemText
                                                    ),
                                                }}
                                                primary={
                                                    <Locale
                                                        path={
                                                            'layouts.Dashboard.components.Sidebar.assessmentTemplates.label'
                                                        }
                                                    />
                                                }
                                            />
                                        </ListItem>
                                    </On>
                                </FeatureFlag>
                            </Permission>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                classes={{
                                    root: classNames(classes.listItem, classes.submenuItem),
                                }}
                                component={NavLinkListItem}
                                to={AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS}
                                isActive={(match, location) =>
                                    location.pathname.indexOf(
                                        `${AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS}`
                                    ) === 0
                                }
                            >
                                <ListItemText
                                    classes={{
                                        primary: classNames(
                                            classes.submenuItemText,
                                            classes.listItemText
                                        ),
                                    }}
                                    primary={
                                        <Locale
                                            path={
                                                'layouts.Dashboard.components.Sidebar.assessmentAssignments.label'
                                            }
                                        />
                                    }
                                />
                            </ListItem>
                        </List>
                    </Collapse>
                </Permission>
            </List>
            <Permission
                forRole
                toPerform="read"
                onAny={[
                    PERMISSIONS.ADMIN,
                    PERMISSIONS.ADVISORY,
                    PERMISSIONS.CONSENTS,
                    PERMISSIONS.DSAR_REQUEST,
                    PERMISSIONS.MONITORING,
                    PERMISSIONS.TRUSTHUB,
                ]}
            >
                <List
                    className={classes.list}
                    component="div"
                    disablePadding
                    subheader={
                        <ListSubheader className={classes.listSubheader}>
                            <Locale path={'layouts.Dashboard.components.Sidebar.services.label'} />
                        </ListSubheader>
                    }
                >
                    <FeatureFlag flag={REPORTS_FEATURE_FLAG}>
                        <On>
                            <Permission
                                forRole
                                toPerform="write"
                                onAny={[
                                    PERMISSIONS.CONSENTS,
                                    PERMISSIONS.DSAR_REQUEST,
                                    PERMISSIONS.MONITORING,
                                ]}
                            >
                                <ListItem
                                    className={classNames(
                                        classes.listMenu,
                                        classes.listItem,
                                        reportsOpen ? classes.listMenuExpanded : '',
                                        reportsActive ? classes.activeListItem : ''
                                    )}
                                    onClick={() => handleToggleReports(!reportsOpen)}
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <ReportsIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.listItemText }}
                                        primary={
                                            <Locale
                                                path={
                                                    'layouts.Dashboard.components.Sidebar.reports.label'
                                                }
                                            />
                                        }
                                    />
                                    {reportsOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse
                                    in={reportsOpen}
                                    timeout="auto"
                                    classes={{
                                        root: classNames(classes.submenu),
                                    }}
                                >
                                    <List component="div" disablePadding>
                                        <Permission
                                            forRole
                                            toPerform="write"
                                            on={PERMISSIONS.CONSENTS}
                                        >
                                            <ListItem
                                                activeClassName={classes.activeListItem}
                                                classes={{
                                                    root: classNames(
                                                        classes.listItem,
                                                        classes.submenuItem
                                                    ),
                                                }}
                                                component={NavLinkListItem}
                                                to={AuthenticatedPaths.REPORTS_CONSENT}
                                                isActive={(match, location) =>
                                                    match
                                                        ? true
                                                        : location.pathname.indexOf(
                                                              AuthenticatedPaths.REPORTS_CONSENT
                                                          ) === 0
                                                }
                                            >
                                                <ListItemText
                                                    classes={{
                                                        primary: classNames(
                                                            classes.submenuItemText,
                                                            classes.listItemText
                                                        ),
                                                    }}
                                                    primary={
                                                        <Locale
                                                            path={
                                                                'layouts.Dashboard.components.Sidebar.reports.submenu.consent.label'
                                                            }
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                        </Permission>
                                        <Permission
                                            forRole
                                            toPerform="write"
                                            on={PERMISSIONS.DSAR_REQUEST}
                                        >
                                            <ListItem
                                                activeClassName={classes.activeListItem}
                                                classes={{
                                                    root: classNames(
                                                        classes.listItem,
                                                        classes.submenuItem
                                                    ),
                                                }}
                                                component={NavLinkListItem}
                                                to={AuthenticatedPaths.REPORTS_DATASUBJECT}
                                                isActive={(match, location) =>
                                                    match
                                                        ? true
                                                        : location.pathname.indexOf(
                                                              AuthenticatedPaths.REPORTS_DATASUBJECT
                                                          ) === 0
                                                }
                                            >
                                                <ListItemText
                                                    classes={{
                                                        primary: classNames(
                                                            classes.submenuItemText,
                                                            classes.listItemText
                                                        ),
                                                    }}
                                                    primary={
                                                        <Locale
                                                            path={
                                                                'layouts.Dashboard.components.Sidebar.reports.submenu.datasubject.label'
                                                            }
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                        </Permission>
                                        <Permission
                                            forRole
                                            toPerform="write"
                                            on={PERMISSIONS.MONITORING}
                                        >
                                            <ListItem
                                                activeClassName={classes.activeListItem}
                                                classes={{
                                                    root: classNames(
                                                        classes.listItem,
                                                        classes.submenuItem
                                                    ),
                                                }}
                                                component={NavLinkListItem}
                                                to={AuthenticatedPaths.REPORTS_VENDOR}
                                                isActive={(match, location) =>
                                                    match
                                                        ? true
                                                        : location.pathname.indexOf(
                                                              AuthenticatedPaths.REPORTS_VENDOR
                                                          ) === 0
                                                }
                                            >
                                                <ListItemText
                                                    classes={{
                                                        primary: classNames(
                                                            classes.submenuItemText,
                                                            classes.listItemText
                                                        ),
                                                    }}
                                                    primary={
                                                        <Locale
                                                            path={
                                                                'layouts.Dashboard.components.Sidebar.reports.submenu.vendor.label'
                                                            }
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                        </Permission>
                                    </List>
                                </Collapse>
                            </Permission>
                        </On>
                    </FeatureFlag>
                    <Permission toPerform="view" on={AuthenticatedPaths.TRUSTHUB}>
                        <Permission forRole toPerform="read" on={PERMISSIONS.TRUSTHUB}>
                            <List component="div" className={classes.list} disablePadding>
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    component={NavLinkListItem}
                                    to={AuthenticatedPaths.TRUSTHUB}
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <TrusthubIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.listItemText }}
                                        primary={
                                            <Locale
                                                path={
                                                    'layouts.Dashboard.components.Sidebar.trusthub.label'
                                                }
                                            />
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Permission>
                    </Permission>
                    <List component="div" className={classes.list} disablePadding>
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLinkListItem}
                            to={`${AuthenticatedPaths.RESOURCES}/templates`}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <TemplatesIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={
                                    <Locale
                                        path={
                                            'layouts.Dashboard.components.Sidebar.templates.label'
                                        }
                                    />
                                }
                            />
                        </ListItem>
                    </List>
                    {canManageVendors && (
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.LEGISLATION}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <LegislationIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={
                                    <Locale
                                        path={
                                            'layouts.Dashboard.components.Sidebar.regulatoryGuidance.label'
                                        }
                                    />
                                }
                            />
                        </ListItem>
                    )}

                    <Permission
                        forRole
                        toPerform="read"
                        onAny={[PERMISSIONS.ADMIN, PERMISSIONS.DSAR_REQUEST]}
                    >
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.GDPR}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <GDPRIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={
                                    <Locale
                                        path={
                                            'layouts.Dashboard.components.Sidebar.gdprRepresentative.label'
                                        }
                                    />
                                }
                            />
                        </ListItem>
                    </Permission>
                    <Permission forRole toPerform="read" on={PERMISSIONS.ADVISORY}>
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.ASK_ANALYST}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <AskExpertIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={
                                    <Locale
                                        path={
                                            'layouts.Dashboard.components.Sidebar.consultPrivacyTeam.label'
                                        }
                                    />
                                }
                            />
                        </ListItem>
                    </Permission>
                </List>
            </Permission>
        </nav>
    );
});

Sidebar.propTypes = {
    canManageSubjectRights: PropTypes.bool,
    canManageVendors: PropTypes.bool,
    canReadDataMapping: PropTypes.bool,
    className: PropTypes.string,
    hasPermissionToReadAssessments: PropTypes.bool,
    hasPermissionToReadUnifiedConsent: PropTypes.bool,
    hasPermissionToViewAssessments: PropTypes.bool,
    hasPermissionToViewUnifiedConsent: PropTypes.bool,
    location: PropTypes.object,
    submenus: PropTypes.shape({
        assessments: PropTypes.shape({
            open: PropTypes.bool,
        }),
        consent: PropTypes.shape({
            open: PropTypes.bool,
        }),
        dataMapping: PropTypes.shape({
            open: PropTypes.bool,
        }),
        dsar: PropTypes.shape({
            open: PropTypes.bool,
        }),
        reports: PropTypes.shape({
            open: PropTypes.bool,
        }),
        vendorManagement: PropTypes.shape({
            open: PropTypes.bool,
        }),
    }),
    toggleSubmenu: PropTypes.func,
};

export default Sidebar;
