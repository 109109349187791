/* eslint-disable camelcase */
import Rollbar from 'rollbar';
import noop from 'lodash/noop';

const isDev = ['dev', 'development', 'ci'].includes(process.env.MODE);

const rollbar = isDev
    ? console
    : new Rollbar({
          accessToken: process.env.ROLLBAR_POST_CLIENT_ITEM_KEY,
          captureUncaught: true,
          captureUnhandledRejections: true,
          payload: {
              environment: process.env.MODE,
              client: {
                  javascript: {
                      source_map_enabled: true,
                      code_version: process.env.VERSION,
                      guess_uncaught_frames: true,
                  },
              },
          },
      });

const Logger = {
    assert: noop,
    clear: noop,
    count: noop,
    countReset: noop,
    debug: rollbar.debug ? rollbar.debug.bind(rollbar) : noop,
    dir: noop,
    dirxml: noop,
    error: rollbar.error ? rollbar.error.bind(rollbar) : noop,
    exception: rollbar.error ? rollbar.error.bind(rollbar) : noop,
    group: noop,
    groupCollapsed: noop,
    groupEnd: noop,
    info: rollbar.info ? rollbar.info.bind(rollbar) : noop,
    log: isDev ? console.log : noop /* eslint-disable-line no-console */,
    profile: noop,
    profileEnd: noop,
    table: noop,
    time: noop,
    timeEnd: noop,
    timeLog: noop,
    timeStamp: noop,
    trace: noop,
    warn: rollbar.warning ? rollbar.warning.bind(rollbar) : noop,
};

export default Logger;
