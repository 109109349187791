import * as AskAnalyst from '../../askAnalyst/state/AskAnalyst.selectors';
import * as Auth from '../../authentication/state/Auth.selectors';
import * as ConsentConfigDetails from '../../consent/state/ConfigDetails.selectors';
import * as DB from './db/DB.selectors';
import * as DSARFormDetails from '../../dsarForms/state/FormDetails.selectors';
import * as DSARRequestDetails from '../../dsarRequests/state/requestDetails/RequestDetails.selectors';
import * as DSARTypes from '../../dsarRequests/state/requestTypes/RequestTypes.selectors';
import * as Dashboard from '../../dashboard/state/Dashboard.selectors';
import * as DataMap from '../../dataMapping/state/DataMap.selectors';
import * as DataStore from '../../dataStores/state/DataStore.selectors';
import * as LitigationDetails from '../../litigation/state/LitigationDetails.selectors';
import * as Locale from './locale/Locale.selectors';
import * as Nav from './nav/Nav.selectors';
import * as NotificationList from '../../notifications/state/NotificationList.selectors';
import * as OrganizationDetails from '../../organizations/state/OrganizationDetails.selectors';
import * as OrganizationsList from '../../organizations/state/OrganizationsList.selectors';
import * as Quicksight from '../../reports/state/Quicksight.selectors';
import * as TrusthubPageDetails from '../../trusthub/state/TrusthubPageDetails.selectors';
import * as UI from './ui/UI.selectors';
import * as UserDetails from '../../users/state/UserDetails.selectors';
import * as Userpilot from './userpilot/Userpilot.selectors';

export default {
    AskAnalyst,
    Auth,
    ConsentConfigDetails,
    Dashboard,
    DataMap,
    DataStore,
    DB,
    DSARRequestDetails,
    DSARFormDetails,
    DSARTypes,
    LitigationDetails,
    Locale,
    Nav,
    NotificationList,
    OrganizationsList,
    OrganizationDetails,
    Quicksight,
    TrusthubPageDetails,
    UI,
    UserDetails,
    Userpilot,
};
