import { types as ActionTypes, asyncEvents as AsyncEvents } from './Reporting.actions';
import {
    errorReducer,
    genericReducer,
    loadingReducer,
    passthroughReducer,
} from '../../common/helpers/ReduxHelpers';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    error: null,
    filters: {
        dateRange: [],
        forms: [],
        jurisdictions: [],
        requestTypes: [],
    },
    loading: false,
};

export default reduceReducers(
    initialState,
    combineReducers({
        filters: passthroughReducer(initialState.filters),
        loading: loadingReducer(
            initialState.loading,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
        error: errorReducer(
            initialState.error,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
    }),
    genericReducer(initialState, {
        [ActionTypes.setFilters]: (state, filters) => ({
            ...state,
            filters: {
                ...state.filters,
                ...filters,
            },
        }),
        [AuthActionTypes.logout]: () => initialState,
    })
);
