import { types as ActionTypes, asyncEvents as AsyncEvents } from './AuthCustomer.actions';
import {
    CRUDDetailsReducers,
    genericReducer,
    passthroughReducer,
} from '../../../common/helpers/ReduxHelpers';
import activity, { initialState as initialActivity } from './activity/AuthCustomerActivity.reducer';
import consentConfigs, {
    initialState as initialConsentConfig,
} from './consentConfigs/AuthCustomerConsentConfigs.reducer';
import dsar, { initialState as initialDSAR } from './dsar/AuthCustomerDSAR.reducer';
import users, { initialState as initialUsers } from './users/AuthCustomerUsers.reducer';

import { BROWSE_CUSTOMER } from '../../../common/services/customer';
import { types as NavActionTypes } from '../../../common/state/nav/Nav.actions';
import UIActions from '../../../common/state/ui/UI.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import schema from '../../../common/state/db/customer/Customer.schema';

export const initialState = {
    activity: initialActivity,
    consentConfigs: initialConsentConfig,
    dsar: initialDSAR,
    users: initialUsers,
    subscription: BROWSE_CUSTOMER.subscription,
    beaconId: BROWSE_CUSTOMER.beaconId,
    canChat: BROWSE_CUSTOMER.canChat,
    canEmail: BROWSE_CUSTOMER.canEmail,
    shortCode: BROWSE_CUSTOMER.shortCode,
    permissions: BROWSE_CUSTOMER.permissions,
    id: null,
    error: null,
    loading: false,
};

export const defaultError = 'An error occurred during authorization';

export default reduceReducers(
    initialState,
    combineReducers({
        activity,
        consentConfigs,
        dsar,
        users,
        ...CRUDDetailsReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[key]),
            schema
        ),
        beaconId: passthroughReducer(initialState.beaconId),
        canChat: genericReducer(initialState.canChat, {
            [NavActionTypes.changeCustomer]: (state, customer = BROWSE_CUSTOMER) => {
                const { canChat = initialState.canChat } = customer;
                return canChat;
            },
            [ActionTypes.fetchDetailsSuccess]: (state, { response: customer }) => {
                const { canChat = BROWSE_CUSTOMER.canChat } = customer;
                return canChat;
            },
        }),
        subscription: genericReducer(initialState.subscription, {
            [ActionTypes.changeCustomer]: (state, { response: customer }) => {
                const { subscription = BROWSE_CUSTOMER.subscription } = customer;
                return subscription;
            },
            [ActionTypes.routeChanged]: (state, { response: customer }) => {
                const { subscription = BROWSE_CUSTOMER.subscription } = customer;
                return subscription;
            },
            [ActionTypes.fetchDetailsSuccess]: (state, { response: customer }) => {
                const { subscription = BROWSE_CUSTOMER.subscription } = customer;

                return subscription;
            },
            [UIActions.openUserDetails]: (state, { response: customer }) => {
                const { subscription = BROWSE_CUSTOMER.subscription } = customer;
                return subscription;
            },
        }),
        canEmail: genericReducer(initialState.canEmail, {
            [NavActionTypes.changeCustomer]: (state, customer = BROWSE_CUSTOMER) => {
                const { canEmail = initialState.canEmail } = customer;
                return canEmail;
            },
            [ActionTypes.fetchDetailsSuccess]: (state, { response: customer }) => {
                const { canEmail = BROWSE_CUSTOMER.canEmail } = customer;
                return canEmail;
            },
        }),
        shortCode: genericReducer(initialState.shortCode, {
            [NavActionTypes.changeCustomer]: (state, customer = BROWSE_CUSTOMER) => {
                const { shortCode = initialState.shortCode } = customer;
                return shortCode;
            },
            [ActionTypes.fetchDetailsSuccess]: (state, { response: customer }) => {
                const { shortCode = BROWSE_CUSTOMER.shortCode } = customer;
                return shortCode;
            },
        }),
        permissions: genericReducer(initialState.permissions, {
            [NavActionTypes.changeCustomer]: (state, customer) => {
                const { permissions = initialState.permissions } = customer;
                return permissions;
            },
            [ActionTypes.fetchDetailsSuccess]: (state, { response: customer }) => {
                const { permissions = BROWSE_CUSTOMER.permissions } = customer;
                return permissions;
            },
            // admin dev tool only
            [ActionTypes.changeCustomerPlan]: (state, permissions) => {
                return permissions;
            },
        }),
    }),
    genericReducer(initialState, {
        [NavActionTypes.changeCustomer]: (state, customer = BROWSE_CUSTOMER) => {
            const { customerId = BROWSE_CUSTOMER.id } = customer;
            return {
                ...state,
                id: customerId,
            };
        },
    })
);
